// workaround for https://github.com/vuejs/core/issues/5844
export function useSafeMountedEl(
  element,
  callback = () => {},
  waitOnMounted = true,
  checkTimeLimit = 1000,
  checkInterval = 75
) {
  checkElement(Array.isArray(element) ? element : [element]);

  function checkElement(el) {
    if (!el.length) {
      console.warn(
        "[useSafeOnMounted] element param must be a ref or an array of refs"
      );
    } else if (process.client) {
      let checkTimeout;
      let checksLeft = checkTimeLimit / checkInterval;

      function clear() {
        if (checkTimeout) {
          clearTimeout(checkTimeout);
          checkTimeout = null;
        }
      }

      function check() {
        clear();

        const ok = el.every((e) => {
          return e.value?.$el?.isConnected || e.value?.isConnected;
        });

        if (ok) {
          callback();
        } else if (--checksLeft > 0) {
          setTimeout(check, checkInterval);
        }
      }

      if (waitOnMounted) {
        onMounted(check);
      } else {
        check();
      }

      onBeforeUnmount(clear);
    }
  }
}
