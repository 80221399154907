import tailwindConfig from "#tailwind-config";

// tailwindConfig.theme.width[4];
// // => '1rem'

// tailwindConfig.theme.screens.md;
// // => '768px'

// tailwindConfig.theme.boxShadow["2xl"];
// // => '0 25px 50px -12px rgba(0, 0, 0, 0.25)'

export default () => ({
  theme: computed(() => tailwindConfig.theme),
  colors: computed(() => tailwindConfig.theme.colors),
  spacing: computed(() => tailwindConfig.theme.spacing),
  screens: computed(() => tailwindConfig.theme.screens),
});
