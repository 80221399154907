// import last from "lodash/last";
import { Cloudinary } from "@cloudinary/url-gen";
import { autoGravity, compass } from "@cloudinary/url-gen/qualifiers/gravity";

// Import required actions.
import { fill } from "@cloudinary/url-gen/actions/resize";
import { useRootStore } from "@/stores/root";

export const useCdn = () => {
  // Create a Cloudinary instance and set your cloud name.

  const rootStore = useRootStore();

  const cld = new Cloudinary({
    cloud: {
      cloudName: rootStore.cloudinary.cloud,
    },
    url: {
      secure: true, // force https, set to false to force http
      analytics: false, // enable Google Analytics
    },
  });

  function fromCloudinary(url, params) {
    if (!url) {
      return "";
    }

    // EXTRACT ID FROM URL
    const last = (arr) => arr[arr.length - 1];
    let id = last(url.split("/"));
    id = `${rootStore.cloudinary.folder}/${id.replace(/(.*)\..*/, "$1")}`;

    const width = params?.width || params;

    // Create a transformation.
    // fill: cropping method to generate and deliver an image that completely fills the requested size while retaining the original aspect ratio
    // https://cloudinary.com/documentation/resizing_and_cropping
    const resizeAction = fill();

    // regexp is a video
    if (url.match(/.*\.(mp4|webm|ogv)/) || /\/video\//.test(url)) {
      // Instantiate a CloudinaryImage object for the image with public ID, 'front_face'.
      const myVideo = cld.video(id);
      if (
        /^auto$|^auto:best$|^auto:good$|^auto:eco$|^auto:low$/.test(
          params?.quality
        )
      ) {
        myVideo.quality(params.quality);
      } else if (params?.quality) {
        console.warn("Error: quality must be auto or auto:best|good|eco|low");
      }
      resizeAction.width(width || 1920);
      myVideo.resize(resizeAction);
      return myVideo.toURL();
    }

    // Instantiate a CloudinaryImage object for the image with public ID, 'front_face'.
    const myImage = cld.image(id);

    let format = params?.attachment
      ? "jpg"
      : /* rootStore.avif &&  */ width < 3000
      ? "avif"
      : "auto";

    resizeAction.width(width);

    if (params?.height) {
      resizeAction.height(params.height);
    }

    if (params?.autoGravity) {
      resizeAction.gravity(autoGravity());
    } else if (
      params?.compass?.match(
        /^north_east$|^north$|^north_west$|^west$|^south_west$|^south$|^south_east$|^east$|^center$/
      )
    ) {
      resizeAction.gravity(compass(params.compass));
    }

    if (params?.ar) {
      resizeAction.aspectRatio(params.ar);
    }

    // Perform the transformation.
    myImage
      .resize(resizeAction)
      .quality(params?.quality ? params.quality : "auto:eco")
      .format(format);

    return myImage.toURL();
  }

  function fromThron(params) {
    // https://<clientId>-cdn.thron.com/delivery/public/image/<clientId>/<contentId>/<pkey>/std/<divArea>/<prettyName>?<rtieParams>;

    const clientId = rootStore.thron.clientId;
    // const contentId = rootStore.thron.contentId;
    const pkey = rootStore.thron.pkey;

    if (params?.type == "document") {
      return `https://${clientId}-cdn.thron.com/delivery/public/document/${clientId}/${
        params.id
      }/${pkey}/WEB/${params.filename || params.id}`;
    }

    if (params?.type == "video") {
      return `https://${clientId}-cdn.thron.com/delivery/public/video/${clientId}/${params.src}/${pkey}/WEBHD/${params.src}`;
    }

    const { divArea, contentId, prettyName, contentType } = params;

    let width,
      height = 0,
      scalemode = "centered";

    if (typeof divArea == "object") {
      width = divArea.width;
      if (divArea.height) {
        height = divArea.height;
      } else if (divArea.ar) {
        // multiply width by aspect ratio in a:b format
        height = Math.round(
          (width * divArea.ar.split(" / ")[1]) / divArea.ar.split(" / ")[0]
        );
      }
    } else {
      width = divArea;
    }

    const rtieParams = `?scalemode=${scalemode}&format=auto&quality=auto-medium`;
    const size = `${width}x${height}`;

    return `https://${clientId}-cdn.thron.com/delivery/public/${
      contentType == "thumbnail" ? "thumbnail" : "image"
    }/${clientId}/${contentId}/${pkey}/std/${size}/${prettyName}${rtieParams}`;
  }

  return {
    fromCloudinary,
    fromThron,
  };
};
