<template>
  <article
    component-name="menu-mirage-world"
    data-lenis-prevent
    class="overflow-y-auto overscroll-y-contain grid grid-cols-12 gap-ui bg-skin-base h-full max-h-full [&>*]:max-h-full px-edge"
  >
    <section
      class="w-full col-span-full laptop:col-start-1 laptop:col-end-5 laptop:pr-edge"
    >
      <header
        class="relative top-0 left-0 flex flex-col items-start justify-start w-full laptop:sticky gap-2xl py-2xl laptop:pt-5xl"
      >
        <btn-list
          class="w-full"
          size="small"
          :asset="{
            variant: 'static',
            cdn: 'none',
            aspectRatio: '1 / 1',
            alt: '50 years (1973 - 2023)',
            src: 'https://res.cloudinary.com/dhgy28eyh/image/upload/v1709196230/mirage-staging/logo-50th_2024_vaxuuz.svg',
            width: 80,
            height: 80,
          }"
          :title="$t.texts.menu_mirageworld"
          :items="btnListItems"
        />
      </header>
    </section>

    <section
      class="flex flex-col items-stretch justify-start w-full col-span-full laptop:col-start-6 laptop:col-end-13 gap-2xl pb-[calc(100lvh-100svh)] laptop:pb-2xl laptop:pt-2xl mb-2xl laptop:mb-0"
    >
      <card-launcher
        v-for="item in data.items"
        class="w-full"
        :overline="item.overline"
        :title="item.title"
        :title-details="item.totalProducts || undefined"
        :text="item.text || undefined"
        :action="item.action"
        :asset="{
          ...item.asset,
          aspectRatio: isLaptop ? '3 / 1' : '3 / 2',
          sizes: {
            base: 600,
            phablet: 1020,
            tablet: 1272,
            laptop: 756,
            desktop: 960,
            extdesktop: 1280,
          },
        }"
        :useTracking="{
          event: 'click_sottovoce_menu',
          voce_menu: item.title,
        }"
      />
    </section>
  </article>
</template>

<script setup>
const { storeLocatorEnabled } = useGlobals();
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});
const nuxtApp = useNuxtApp();
const isLaptop = useMediaQuery("(min-width: 1272px)");
const btnListItems = computed(() => {
  let arr;
  if (isLaptop.value) {
    arr = [];
    arr.push({
      title: nuxtApp.$t.labels.mirage_catalogs,
      icon: "download",
      action: { to: "/resources/collections" },
    });

    if (storeLocatorEnabled.value) {
      arr.push({
        title: nuxtApp.$t.labels.find_reseller,
        icon: "store-locator",
        action: { to: "/store-locator" },
      });
    }

    arr.push({
      title: nuxtApp.$t.labels.info_request,
      icon: "info",
      action: { to: "/support" },
    });

    arr.push({
      title: nuxtApp.$t.labels.projects,
      icon: "world",
      action: { to: "/stories/projects" },
    });
  }

  return arr;
});
</script>
