<template>
  <wrapper-reveal
    component-name="txt-mandatory-fields"
    reveal="fade-from-right"
  >
    <div
      v-html="$t.labels.form_mandatory_field"
      class="text-skin-muted text-body-small"
    ></div>
  </wrapper-reveal>
</template>
