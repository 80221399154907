<template>
  <component
    component-name="wrapper-split-text"
    ref="tpl_root"
    :is="tag"
    :data-split-text="splitTextInstance ? true : false"
    :data-sufer="surferState || undefined"
    :data-reveal="finalReveal"
    class="overflow-hidden [&_ul]:list-inside [&_ol]:list-inside [&_ul]:list-disc [&_ol]:list-decimal"
  >
    <div
      ref="tpl_inner"
      class="relative top-0 left-0 inline-block [&>*:nth-child(2)]:overflow-hidden [&>*:nth-child(2)]:inline-block"
      :class="{
        '[&>*:nth-child(2)]:invisible': finalReveal,
        '[&>*:nth-child(2)>.line]:overflow-hidden':
          finalReveal === 'lines' || finalReveal === 'words',
        '[&>*:nth-child(2)_del>*]:line-through': finalReveal === 'lines',
        '[&>*:nth-child(2)_span[style]>*]:underline': finalReveal === 'lines',
      }"
    >
      <!-- SURFER TRIGGER -->
      <div
        ref="tpl_trigger"
        inert
        class="invisible absolute inset-0 inline-block"
        v-surfer="surfer"
        @surfer-offscreen-top="surferState = 'offscreen-top'"
        @surfer-visible="surferState = 'visible'"
        @surfer-offscreen-bottom="surferState = 'offscreen-bottom'"
      ></div>
      <!-- end SURFER TRIGGER -->

      <!-- SLOT (use a single node element) -->
      <slot />
      <!-- end SLOT -->
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  reveal: {
    type: String,
    required: true,
    validator: function (value) {
      return ["lines", "words", "chars"].indexOf(value) !== -1;
    },
  },
});
const { isMobile } = useDevice();
const nuxtApp = useNuxtApp();
const tpl_root = templateRef("tpl_root");
const tpl_inner = templateRef("tpl_inner");
const tpl_trigger = templateRef("tpl_trigger");

const surferState = ref();
const { width: windowW } = useWindowSize();
const splitTextInstance = ref();

const surfer = computed(() => {
  let s;

  if (
    finalReveal.value === "lines" ||
    finalReveal.value === "words" ||
    finalReveal.value === "chars" ||
    finalReveal.value === "opacity"
  ) {
    s = {
      setup: {
        destroyOn: "visible",
      },
      observers: {
        init: true,
        visible: { event: true },
        offscreenTop: { event: true },
        offscreenBottom: { event: true },
      },
    };
  }

  return s;
});

const finalReveal = computed(() => {
  let reveal;

  if (props.reveal) {
    reveal = isMobile ? "opacity" : props.reveal;
  }

  return reveal;
});

useSafeMountedEl([tpl_root, tpl_inner, tpl_trigger], () => {
  //
});

let tl;
/* ANIMATE LINES & WORDS */
function animateLines(staggerWords = false) {
  // https://greensock.com/forums/topic/18243-split-text-confused/#comment-84134 (currently not in use, but could help...)

  splitTextInstance.value = new SplitText(tpl_inner.value.children[1], {
    type: "lines, words",
    linesClass: "line",
    wordsClass: "word",
  });

  tl = gsap.timeline({
    onComplete: () => {
      if (tpl_root.value) tpl_root.value.classList.add("revealed");
    },
  });

  tl.set(tpl_inner.value.children[1], {
    visibility: "visible",
    perspective: 400,
  });

  for (let i = 0, len = splitTextInstance.value.lines.length; i < len; i++) {
    let $els = splitTextInstance.value.lines[i].getElementsByClassName("word");

    for (let ind = 0, l = $els.length; ind < l; ind++) {
      tl.fromTo(
        $els[ind],
        { yPercent: 110 },
        {
          onStart: () => {
            if ($els[ind].parentElement.nodeName.toLocaleLowerCase() === "li") {
              $els[ind].parentElement.classList.add("revealed");
            }
          },
          duration: 0.5,
          yPercent: 0,
          ease: "sine.inOut",
        },
        0.07 * i + (staggerWords ? 0.035 * ind : 0)
      );
    }
  }
}
/* end ANIMATE LINES & WORDS */

/* ANIMATE CHARS */
function animateChars() {
  function getRange(number, { r1, r2 }) {
    const n = ((number - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
    // n can't be less than r2[0] or greater than r2[1]
    if (n < r2[0]) return r2[0];
    if (n > r2[1]) return r2[1];
    return n;
  }

  splitTextInstance.value = new SplitText(tpl_inner.value.children[1], {
    type: "lines, words, chars",
    linesClass: "line",
    wordsClass: "word",
    charsClass: "char",
  });

  const paramsDuration = {
    r1: [1, 10],
    r2: [1, 3], // con 10 righe diventa 3 volte piu veloce (max)
  };

  tl = gsap.timeline({
    onComplete: () => {
      if (tpl_root.value) tpl_root.value.classList.add("revealed");
    },
  });
  tl.set(tpl_inner.value.children[1], {
    visibility: "visible",
    perspective: 400,
  });
  tl.set(splitTextInstance.value.words, { display: "inline-block" });
  tl.set(splitTextInstance.value.chars, { opacity: 0.15 });

  let firstLineChars =
    splitTextInstance.value.lines[0]?.getElementsByClassName("char").length;

  const increment = getRange(
    splitTextInstance.value.lines.length,
    paramsDuration
  );

  for (let i = 0, len = splitTextInstance.value.lines.length; i < len; i++) {
    tl.fromTo(
      splitTextInstance.value.lines[i].getElementsByClassName("char"),
      { opacity: 0.15 },
      {
        duration: 0.35,
        opacity: 1,
        stagger: Math.max(0.02, firstLineChars / 1750),
        ease: "sine.inOut",
      },
      (0.07 / increment) * i
    );
  }
}
/* end ANIMATE CHARS */

/* ANIMATE OPACITY (fallback animation) */
function animateOpacity() {
  tl = gsap.timeline({
    onComplete: () => {
      if (tpl_root.value) tpl_root.value.classList.add("revealed");
    },
  });

  tl.fromTo(
    tpl_inner.value.children[1],
    { visibility: "visible", opacity: 0 },
    {
      duration: 0.6,
      opacity: 1,
      ease: "expo.inOut",
    }
  );
}
/* end ANIMATE OPACITY */

/* ANIMATION FACTORY */
function animationFactory(newVal, oldVal) {
  if (tl) {
    tl.kill();
    tl = null;
  }

  if (splitTextInstance.value) {
    splitTextInstance.value.revert();
    splitTextInstance.value = null;
  }

  gsap.set(tpl_inner.value.children[1], { clearProps: "all" });

  if (tpl_root.value.classList.contains("revealed")) {
    tpl_root.value.classList.remove("revealed");
  }

  if (
    (newVal === "visible" && oldVal === "offscreen-top") ||
    newVal === "offscreen-top"
  ) {
    gsap.set(tpl_inner.value.children[1], { visibility: "visible" });
  } else if (newVal === "offscreen-bottom" || newVal === "visible") {
    gsap.set(tpl_inner.value.children[1], {
      visibility: "hidden",
    });

    if (newVal === "visible") {
      switch (finalReveal.value) {
        case "lines":
          animateLines();
          break;

        case "words":
          animateLines(true);
          break;

        case "chars":
          animateChars();
          break;

        case "opacity":
          animateOpacity();
          break;
      }
    }
  }
}
/* end ANIMATION FACTORY */

watch(windowW, () => {
  animationFactory(
    surferState.value,
    surferState.value === "visible" ? "offscreen-top" : null
  );
});

watch(surferState, (newVal, oldVal) => animationFactory(newVal, oldVal));

onBeforeUnmount(() => {
  if (tl) {
    tl.kill();
    tl = null;
  }
});
</script>

<style>
[component-name="wrapper-split-text"][data-split-text="true"][data-sufer="visible"] {
  &[data-reveal="lines"] li,
  &[data-reveal="words"] li {
    overflow: hidden;
  }

  & [style="text-decoration: underline;"],
  & del {
    text-decoration-color: transparent !important;

    & * {
      text-decoration-color: inherit !important;
    }
  }

  & li::marker {
    color: transparent;
  }

  & li.revealed::marker,
  &.revealed li::marker {
    color: currentColor;
    transition-property: color;
    transition-delay: 300ms;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
  }

  &.revealed {
    & [style="text-decoration: underline;"],
    & del {
      transition-property: text-decoration-color;
      text-decoration-color: currentColor !important;
      transition-duration: 500ms;
      transition-timing-function: ease-in-out;
    }
  }
}
</style>
