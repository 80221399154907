import routerOptions0 from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/var/www/html/mirage/frontend-2/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}