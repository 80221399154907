<template>
  <transition-fade
    :on-before-leave="() => $emit('dismiss')"
    :on-after-leave="() => $emit('dismissed')"
  >
    <div
      v-if="alertVisible"
      component-name="alert"
      :data-skin="tone !== 'info' ? 'black' : undefined"
      class="@container/alert relative top-0 left-0 flex p-2xl @sm/alert:px-3xl rounded-2xl"
      :class="{
        'border border-skin-base': tone === 'info',
        'bg-skin-base': tone === 'success' || tone === 'info',
        'bg-skin-danger': tone === 'error' || tone === 'warning',
      }"
    >
      <btn-icon
        v-if="dismissible"
        size="regular"
        icon="close"
        class="absolute top-lg right-lg"
        :title="$t.labels.close"
        @click.stop.prevent="alertVisible = false"
      />

      <div class="flex items-center gap-2xl text-skin-base">
        <svg-icon v-if="useIcon" :icon="tone" size="giant" class="min-w-min" />

        <div class="flex flex-col items-start gap-lg">
          <div
            class="grow text-body-big select-none text-ballance"
            v-html="text"
          ></div>

          <div v-if="button">
            <btn-pill
              :title="button.title"
              :action="button.action"
              :icon="button.icon"
            />
          </div>
        </div>
      </div>
    </div>
  </transition-fade>
</template>

<script setup>
const props = defineProps({
  tone: {
    type: String,
    required: false,
    validator: function (value) {
      return ["info", "success", "warning", "error"].indexOf(value) !== -1;
    },
    default: "info",
  },
  text: {
    type: String,
    required: true,
  },
  useIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  button: {
    type: String,
    required: false,
  },
  dismissible: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const alertVisible = ref(true);
</script>
