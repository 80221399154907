<template>
  <transition
    v-if="useAppear"
    component-name="transition-fade"
    appear
    :mode="useMode"
    :enter-active-class="`transition-opacity ease-in-out !pointer-events-none ${duration}`"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    :leave-active-class="`transition-opacity ease-in-out !pointer-events-none ${duration}`"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    @before-enter="handleBeforeEnter"
    @after-enter="handleAfterEnter"
    @before-leave="handleBeforeLeave"
    @after-leave="handleAfterLeave"
  >
    <slot />
  </transition>

  <transition
    v-else
    component-name="transition-fade"
    :mode="useMode"
    :enter-active-class="`transition-opacity ease-in-out !pointer-events-none ${duration}`"
    enter-from-class="opacity-0"
    enter-to-class="opacity-100"
    :leave-active-class="`transition-opacity ease-in-out !pointer-events-none ${duration}`"
    leave-from-class="opacity-100"
    leave-to-class="opacity-0"
    @before-enter="handleBeforeEnter"
    @after-enter="handleAfterEnter"
    @before-leave="handleBeforeLeave"
    @after-leave="handleAfterLeave"
  >
    <slot />
  </transition>
</template>

<script setup>
const props = defineProps({
  useMode: {
    type: String,
    required: false,
    validator: function (value) {
      return ["out-in", "in-out"].indexOf(value) !== -1;
    },
    default: "out-in",
  },
  duration: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        ["none", "superslow", "slow", "regular", "quick", "superquick"].indexOf(
          value
        ) !== -1
      );
    },
    default: "regular",
  },
  useAppear: {
    type: Boolean,
    required: false,
    default: true,
  },
  refreshScrolltriggerBeforeEnter: {
    type: Boolean,
    required: false,
    default: true,
  },
  refreshScrolltriggerAfterEnter: {
    type: Boolean,
    required: false,
    default: true,
  },
  refreshScrolltriggerBeforeLeave: {
    type: Boolean,
    required: false,
    default: true,
  },
  refreshScrolltriggerAfterLeave: {
    type: Boolean,
    required: false,
    default: true,
  },
  onBeforeEnter: {
    type: Function,
    required: false,
  },
  onAfterEnter: {
    type: Function,
    required: false,
  },
  onBeforeLeave: {
    type: Function,
    required: false,
  },
  onAfterLeave: {
    type: Function,
    required: false,
  },
});

function handleBeforeEnter() {
  if (props.onBeforeEnter) props.onBeforeEnter();
  if (props.refreshScrolltriggerBeforeEnter) ScrollTrigger.refresh();
}

function handleAfterEnter() {
  if (props.onAfterEnter) props.onAfterEnter();
  if (props.refreshScrolltriggerAfterEnter) ScrollTrigger.refresh();
}

function handleBeforeLeave() {
  if (props.refreshScrolltriggerBeforeLeave) ScrollTrigger.refresh();
  if (props.onBeforeLeave) props.onBeforeLeave();
}

function handleAfterLeave() {
  if (props.refreshScrolltriggerAfterLeave) ScrollTrigger.refresh();
  if (props.onAfterLeave) props.onAfterLeave();
}

const duration = computed(() => {
  let d;
  switch (props.duration) {
    case "superslow":
      d = "duration-1000";
      break;

    case "slow":
      d = "duration-700";
      break;

    case "quick":
      d = "duration-300";
      break;

    case "superquick":
      d = "duration-150";
      break;

    case "none":
      d = "duration-0";
      break;

    case "regular":
    default:
      d = "duration-500";
      break;
  }

  return d;
});
</script>
