<template>
  <div class="flex flex-col gap-md">
    <client-only>
      <btn-nav
        class="!text-skin-muted !text-body-big"
        :action="{ href }"
        :title="$t.labels.read_privacy_policy"
      />
    </client-only>
    <form-kit
      component-name="input-privacy"
      type="checkbox"
      :label="$t.texts.checkbox_privacy"
      name="privacy"
      validation="accepted"
      validation-visibility="dirty"
      :validation-label="$t.labels.privacy_policy"
    />
  </div>
</template>

<script setup>
const route = useRoute();
const href = computed(() => {
  if (process.client) {
    return `${window.location.origin}/${route.params.country}/${route.params.locale}/info-and-legal/privacy-policy`;
  }
});
</script>
