<template>
  <div
    component-name="collapse-group"
    class="flex flex-col items-stretch gap-lg"
  >
    <wrapper-split-text v-if="title" reveal="words" class="self-start w-11/12">
      <div class="w-full text-heading-5" v-html="title"></div>
    </wrapper-split-text>

    <div
      ref="tpl_inner"
      class="flex flex-col w-full border-current divide-y divide-current text-skin-base border-y"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  alwaysOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  title: {
    type: String,
    required: false,
  },
});
const tpl_inner = ref(null);
const detailsList = ref(null);

if (!props.alwaysOpen) {
  useMutationObserver(
    tpl_inner,
    (mutations) => {
      if (!detailsList.value) return;

      if (mutations[0].oldValue !== "") {
        for (let i = 0, len = detailsList.value.length; i < len; i++) {
          if (detailsList.value[i] !== mutations[0].target) {
            detailsList.value[i].removeAttribute("open");
          }
        }
      }
    },
    {
      subtree: true,
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ["open"],
    }
  );
}

useSafeMountedEl(tpl_inner, () => {
  if (!props.alwaysOpen) {
    detailsList.value = tpl_inner.value.getElementsByTagName("details");
  }
});
</script>
