<template>
  <div
    component-name="form-web-to-lead-picker"
    class="flex flex-col items-stretch gap-3xl @container/form-web-to-lead-picker"
  >
    <form-kit type="form" :actions="false" v-model="model">
      <div
        class="flex flex-col w-full @md/form-web-to-lead-picker:flex-row gap-x-4xl gap-y-2xl"
        :class="{
          'tablet:w-8/12 laptop:w-7/12 desktop:w-6/12 extdesktop:w-5/12': 1 > 2,
          '@md/form-web-to-lead-picker:w-8/12 @lg/form-web-to-lead-picker:w-7/12 @xl/form-web-to-lead-picker:w-6/12 @2xl/form-web-to-lead-picker:w-5/12':
            userStore.logged,
        }"
      >
        <div
          :class="{
            hidden: userStore.logged,
            contents: !userStore.logged,
          }"
        >
          <form-kit
            type="select"
            name="user_type"
            :label="$t.labels.user_type"
            :options="userType"
            :placeholder="$t.labels.select_option"
            :disabled="userStore.logged"
          />
        </div>

        <form-kit
          type="select"
          name="support_type"
          :label="$t.labels.support_type"
          :options="formOptions"
          :placeholder="$t.labels.select_option"
          :disabled="!model.user_type"
        />

        <btn-pill
          class="self-start @md/form-web-to-lead-picker:self-center min-w-min"
          :size="submitBtnSize"
          :disabled="!submitEnabled"
          :title="$t.labels.proceed"
          @click.prevent="handleClick"
        />
      </div>
    </form-kit>
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const props = defineProps({
  scope: {
    type: String,
    required: true,
    validator: function (value) {
      return ["page", "value"].indexOf(value) !== -1;
    },
  },
  submitBtnSize: {
    type: String,
    required: false,
    validator: function (value) {
      return ["regular", "big"].indexOf(value) !== -1;
    },
    default: "big",
  },
});

const nuxtApp = useNuxtApp();
const route = useRoute();
const router = useRouter();
const model = ref({});
const formOptions = ref([]);
const emit = defineEmits(["webToLead"]);

const userStore = useUserStore();
if (userStore.logged) {
  model.value = {
    user_type: userStore.user?.type,
  };
}

const userType = computed(() => {
  let arr = [];

  if (userStore.logged && userStore.user?.type === "professional") {
    arr = [
      { label: nuxtApp.$t.labels.professional_user, value: "professional" },
    ];
  } else if (userStore.logged && userStore.user?.type === "private") {
    arr = [{ label: nuxtApp.$t.labels.private_user, value: "private" }];
  } else {
    arr = [
      { label: nuxtApp.$t.labels.professional_user, value: "professional" },
      { label: nuxtApp.$t.labels.private_user, value: "private" },
    ];
  }

  return arr;
});

const privateOptions = computed(() => {
  return [
    {
      label: nuxtApp.$t.labels.commercial_consultancy,
      value:
        props.scope === "page"
          ? "/support/private-commercial-consultancy"
          : "commercial-consultancy",
    },
    {
      label: nuxtApp.$t.labels.product_info_and_solutions,
      value:
        props.scope === "page"
          ? "/support/private-product-info-and-solutions"
          : "product-info-and-solutions",
    },
    {
      label:
        nuxtApp.$t.labels.after_sales_and_installation_technical_assistance,
      value:
        props.scope === "page"
          ? "/support/private-after-sales-and-installation-technical-assistance"
          : "after-sales-and-installation-technical-assistance",
    },
  ];
});

const professionalOptions = computed(() => {
  let arr = [];

  arr.push({
    label: nuxtApp.$t.labels.commercial_consultancy,
    value:
      props.scope === "page"
        ? "/support/professional-commercial-consultancy"
        : "commercial-consultancy",
  });

  arr.push({
    label: nuxtApp.$t.labels.product_info_and_solutions,
    value:
      props.scope === "page"
        ? "/support/professional-product-info-and-solutions"
        : "product-info-and-solutions",
  });

  arr.push({
    label: nuxtApp.$t.labels.after_sales_and_installation_technical_assistance,
    value:
      props.scope === "page"
        ? "/support/professional-after-sales-and-installation-technical-assistance"
        : "after-sales-and-installation-technical-assistance",
  });

  arr.push({
    label: nuxtApp.$t.labels.hr_images_request,
    value:
      props.scope === "page"
        ? "/support/professional-hr-images-request"
        : "hr-images-request",
  });

  return arr;
});

onMounted(() => {
  /* Update model according to current route */
  switch (route.name) {
    case "country-locale-support-private-commercial-consultancy":
      model.value = {
        user_type: "private",
        support_type: privateOptions.value[0].value,
      };
      break;

    case "country-locale-support-private-product-info-and-solutions":
      model.value = {
        user_type: "private",
        support_type: privateOptions.value[1].value,
      };
      break;

    case "country-locale-support-private-after-sales-and-installation-technical-assistance":
      model.value = {
        user_type: "private",
        support_type: privateOptions.value[2].value,
      };
      break;

    case "country-locale-support-professional-commercial-consultancy":
      model.value = {
        user_type: "professional",
        support_type: professionalOptions.value[0].value,
      };
      break;

    case "country-locale-support-professional-product-info-and-solutions":
      model.value = {
        user_type: "professional",
        support_type: professionalOptions.value[1].value,
      };
      break;

    case "country-locale-support-professional-after-sales-and-installation-technical-assistance":
      model.value = {
        user_type: "professional",
        support_type: professionalOptions.value[2].value,
      };
      break;

    case "country-locale-support-professional-hr-images-request":
      model.value = {
        user_type: "professional",
        support_type: professionalOptions.value[3].value,
      };
      break;
  }
});

/* NOT WORKING WITH COMPUTED! */
watch(model, (value, old) => {
  if (value.user_type !== old.user_type) {
    model.value.support_type = undefined;
  }

  if (value.user_type === "private") {
    formOptions.value = privateOptions.value;
  } else if (value.user_type === "professional") {
    formOptions.value = professionalOptions.value;
  } else {
    formOptions.value = [];
  }
});

const submitEnabled = computed(() => {
  return (
    model.value.support_type != undefined &&
    `/${route.params.country}/${route.params.locale}${model.value.support_type}` !=
      route.path
  );
});

function handleClick() {
  if (props.scope === "page") {
    router.push(
      `/${route.params.country}/${route.params.locale}${model.value.support_type}`
    );
  } else if (props.scope === "value") {
    emit("webToLead", {
      user: userType.value,
      variant: model.value.support_type,
    });
  } else return;
}
</script>
