<template>
  <div
    ref="tpl_root"
    component-name="form-web-to-lead"
    class="flex items-stretch"
  >
    <div class="@container/form-web-to-lead flex items-stretch w-full">
      <menu-user
        v-if="variant === 'hr-images-request' && !userStore.logged"
        class="w-full"
      />
      <input-form
        v-else
        class="w-full"
        ref="tpl_form"
        :use-mandatory-fields-warning="true"
        :use-privacy="true"
        :use-copyright="
          user === 'professional' && variant === 'hr-images-request'
        "
        :use-marketing="userStore.user?.marketing != '1'"
        :success-dismissible="true"
        :alert-dismissed-callback="onAlertDismissed"
        :success-message="$t.texts.form_support__message_success"
        :submit-callback="handleSubmit"
        :success-callback="handleSuccess"
        @update:value="handleUpdate"
        @validation-error="handleSubmitInvalid"
        v-model="model"
      >
        <!-- HIDDEN INPUTS -->
        <input-hidden
          v-if="leadSource?.val"
          name="lead-source"
          :value="leadSource.val"
        />
        <input-hidden
          v-if="userStore.user?.marketing == '1'"
          name="marketing"
          :value="true"
        />

        <input-language />
        <!-- end HIDDEN INPUTS -->

        <!-- LOGGED USER -->
        <template v-if="userStore.logged">
          <input-hidden
            name="user_type"
            :value="
              userStore.user.type === 'professional'
                ? 'Professionista'
                : 'Privato'
            "
          />
          <details
            :open="
              !minimizePersonalInfo || expandPersonalInfo ? true : undefined
            "
            class="w-full [&[open]_summary]:hidden"
          >
            <btn
              button-tag="summary"
              class="text-body w-full list-item list-none max-w-min"
            >
              <input-static
                :label="$t.labels.info"
                :text="`${$t.labels.details} (...)`"
              />
            </btn>

            <div
              class="grid grid-cols-1 @sm/form-web-to-lead:grid-cols-2 gap-2xl"
            >
              <input-static
                :label="$t.labels.name"
                :text="
                  userStore.user.first_name + ' ' + userStore.user.last_name
                "
              >
                <input-hidden
                  name="first_name"
                  :value="userStore.user.first_name"
                />
                <input-hidden
                  name="last_name"
                  :value="userStore.user.last_name"
                />
              </input-static>

              <input-static
                :label="$t.labels.email"
                :text="userStore.user.email"
              >
                <input-hidden name="email" :value="userStore.user.email" />
                <input-hidden
                  name="email_confirm"
                  :value="userStore.user.email"
                />
              </input-static>

              <input-static
                :label="$t.labels.country"
                :text="userStore.user.country_code"
              >
                <input-hidden
                  name="country_code"
                  :value="userStore.user.country_code"
                  :is-required="true"
                />
              </input-static>

              <input-static
                v-if="
                  /^IT$|^US$/.test(userStore.user.country_code) &&
                  userStore.user.state_code
                "
                :label="
                  userStore.user.country_code === 'IT'
                    ? $t.labels.province
                    : $t.labels.state
                "
                :text="userStore.user.state_code"
              >
                <input-hidden
                  name="state_code"
                  :value="userStore.user.state_code"
                />
              </input-static>

              <input-state
                v-if="
                  /^IT$|^US$/.test(userStore.user.country_code) &&
                  !userStore.user.state_code
                "
                :country-code="userStore.user.country_code"
              />

              <!-- ONLY FOR variant: commercial-consultancy, hr-images-request -->
              <div
                v-if="
                  (userStore.user.city || userStore.user.zip) &&
                  (variant === 'commercial-consultancy' ||
                    variant === 'hr-images-request')
                "
                class="contents"
              >
                <input-static
                  :label="$t.labels.city"
                  :text="userStore.user.city"
                />
                <input-static
                  :label="$t.labels.zip"
                  :text="userStore.user.zip"
                />
              </div>
              <!-- end ONLY FOR variant: commercial-consultancy, hr-images-request -->

              <template v-if="userStore.user.type === 'professional'">
                <input-static
                  v-if="userStore.user.company"
                  :label="$t.labels.company"
                  :text="userStore.user.company"
                >
                  <input-hidden
                    name="company"
                    :value="userStore.user.company"
                  />
                </input-static>
                <input-company v-else :is-required="true" />

                <input-static
                  :label="$t.labels.profession_title"
                  :text="jobtitlesStore.getLabel(userStore.user.title)"
                >
                  <input-hidden name="title" :value="userStore.user.title" />
                </input-static>
              </template>
            </div>
          </details>

          <!-- SUBJECT & SUPPORT TYPE -->
          <div
            v-if="
              (showSupportType && leadSource?.label) ||
              (useSubject && windowLocationHref)
            "
            class="grid grid-cols-1 @sm/form-web-to-lead:grid-cols-2 gap-2xl w-full"
          >
            <input-static
              v-if="useSubject && windowLocationHref"
              :label="$t.labels.subject"
              :text="pageTitle"
            >
              <input-hidden name="subject" :value="windowLocationHref" />
            </input-static>

            <input-static
              v-if="showSupportType && leadSource?.label"
              :label="$t.labels.support_type"
              :text="leadSource.label"
            />
          </div>
          <!-- end SUBJECT & SUPPORT TYPE -->
        </template>
        <!-- end LOGGED USER -->

        <!-- NOT LOGGED USER -->
        <template v-else>
          <!-- SUBJECT & SUPPORT TYPE -->
          <div
            v-if="
              (showSupportType && leadSource?.label) ||
              (useSubject && windowLocationHref)
            "
            class="grid grid-cols-1 @sm/form-web-to-lead:grid-cols-2 gap-2xl w-full"
          >
            <input-static
              v-if="useSubject && windowLocationHref"
              :label="$t.labels.subject"
              :text="pageTitle"
            >
              <input-hidden name="subject" :value="windowLocationHref" />
            </input-static>

            <input-static
              v-if="showSupportType && leadSource?.label"
              :label="$t.labels.support_type"
              :text="leadSource.label"
            />
          </div>
          <!-- end SUBJECT & SUPPORT TYPE -->

          <input-hidden
            name="user_type"
            :value="user === 'professional' ? 'Professionista' : 'Privato'"
          />

          <input-first-name />

          <input-last-name />

          <input-email :use-confirm="true" />

          <!-- ONLY FOR user: professional -->
          <input-professional-title
            v-if="user === 'professional'"
            :is-required="true"
            :use-company="true"
          />
          <!-- end ONLY FOR user: professional -->

          <input-country :use-states="true" />
        </template>
        <!-- NOT LOGGED USER -->

        <!-- ONLY FOR variant: commercial-consultancy, hr-images-request -->
        <div
          v-if="
            !userStore.user.city ||
            (!userStore.user.zip && /DE|FR|CH/.test(model.country_code))
          "
          class="contents"
        >
          <input-city v-if="!userStore.user.city" />

          <!-- ONLY FOR country_code: DE, FR, CH -->
          <input-zip
            v-if="!userStore.user.zip && /DE|FR|CH/.test(model.country_code)"
          />
          <!-- end ONLY FOR country_code: DE, FR, CH  -->
        </div>
        <!-- end ONLY FOR variant: commercial-consultancy, hr-images-request -->
        <input-message />
      </input-form>
    </div>
  </div>
</template>

<script setup>
import { useJobtitlesStore } from "@/stores/jobtitles";
import { useUserStore } from "@/stores/user";
const userStore = useUserStore();
const jobtitlesStore = useJobtitlesStore();
const windowLocationHref = ref();
const pageTitle = ref("");

const props = defineProps({
  user: {
    type: String,
    validator: function (value) {
      return ["private", "professional"].indexOf(value) !== -1;
    },
  },
  variant: {
    type: String,
    validator: function (value) {
      return (
        [
          "commercial-consultancy",
          "product-info-and-solutions",
          "after-sales-and-installation-technical-assistance",
          "hr-images-request",
        ].indexOf(value) !== -1
      );
    },
  },
  useSubject: {
    type: Boolean,
    required: false,
    default: false,
  },
  showSupportType: {
    type: Boolean,
    required: false,
    default: false,
  },
  minimizePersonalInfo: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const nuxtApp = useNuxtApp();
const model = ref({});
const tpl_form = templateRef("tpl_form");
const tpl_root = templateRef("tpl_root");
const { params } = useRoute();
const expandPersonalInfo = ref(false);

const emit = defineEmits(["alert-dismissed"]);

const leadSource = computed(() => {
  let s = {};

  switch (props.variant) {
    case "commercial-consultancy":
      s.val = "Richiesta Commerciale - WEBSITE";
      s.label = nuxtApp.$t.labels.commercial_consultancy;
      break;

    case "product-info-and-solutions":
      s.val = "Informazioni prodotto - WEBSITE";
      s.label = nuxtApp.$t.labels.product_info_and_solutions;
      break;

    case "after-sales-and-installation-technical-assistance":
      s.val = "Assistenza - WEBSITE";
      s.label =
        nuxtApp.$t.labels.after_sales_and_installation_technical_assistance;
      break;

    case "hr-images-request":
      s.val = "Immagini HR - WEBSITE";
      s.label = nuxtApp.$t.labels.hr_images_request;
      break;
  }

  return s;
});

function onAlertDismissed() {
  emit("alert-dismissed");
}

function handleSuccess(data) {
  if (userStore.logged) {
    userStore.validate();
  }
}

function handleSubmitInvalid(fields) {
  console.log("--- [PARENT WEB TO LEAD] handleSubmitInvalid --->", fields);
  expandPersonalInfo.value = true;
}

function handleUpdate(data) {}

async function handleSubmit(fields, actions) {
  console.log("handleSubmit", fields, actions);

  const data = {
    ...fields.email_group,
    ...Object.keys(fields)
      .filter((key) => key !== "email_group")
      .reduce((obj, key) => {
        obj[key] = fields[key];
        return obj;
      }, {}),
  };

  if (userStore.logged) {
    data.user_id = userStore.user.id;
    data.token = userStore.user.token;
  }

  return $fetch(`/api/lead`, {
    method: "POST",
    body: { ...data, lng: params.locale },
  }).then((response) => {
    if (!response) {
      tpl_form.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
    }

    const { status, error } = response;

    if (status !== "success" || error) {
      tpl_form.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
    }

    return response;
  });
}

onMounted(() => {
  pageTitle.value = document.title.replace(nuxtApp.$t.labels.brand_suffix, "");
  windowLocationHref.value = window.location.href;
});
</script>
