<template>
  <svg
    component-name="svg-atelier"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 150 34"
    class="[&_*]:fill-current w-[150px] h-[34px]"
  >
    <path
      d="M8.809 10.186H6.85L1 23.922h1.494l1.598-3.612h7.514l1.598 3.612h1.426L8.844 10.268l-.035-.082Zm-4.031 8.53 2.904-6.584h.334l2.894 6.584H4.778Zm13.49-6.937h4.831v12.143h1.337V11.779h4.832v-1.593h-11v1.593Zm19.007 6.015h7.642v-1.556h-7.642V11.78h8.296v-1.593h-9.614v13.736h9.71V22.33h-8.393v-4.535Zm16.804-7.608h-1.317v13.736h8.728V22.33h-7.41V10.186Zm14.187 13.736h1.317V10.186h-1.317v13.736Zm10.499-6.128h7.641v-1.556h-7.641V11.78h8.296v-1.593h-9.614v13.736h9.71V22.33h-8.392v-4.535Zm24.998-.369c.718-.79 1.083-1.763 1.083-2.892 0-1.22-.427-2.262-1.271-3.098-.837-.83-1.899-1.25-3.155-1.25h-6.168v13.737h1.317V18.87h4.033l3.821 5.052h1.664l-3.855-5.132a4.265 4.265 0 0 0 2.531-1.365Zm-8.194-.157v-5.489h4.831c.899 0 1.646.261 2.22.776.579.519.86 1.166.86 1.978 0 .8-.281 1.44-.86 1.96-.574.514-1.321.775-2.22.775h-4.831Zm38.34-8.511-.035-.083h-2.932l-8.413 19.911h2.168l2.796-6.424 4.638-10.603h.613l5.518 12.669 1.912 4.358h2.071l-8.336-19.829ZM115.083 34h7.464l.708-1.622h-6.546V1.622h30.809v30.756h-5.902l.71 1.622h6.817V0h-34.06v34Z"
    />
  </svg>
</template>
