<template>
  <form-kit
    v-if="useConfirm"
    component-name="input-password"
    type="group"
    name="password_group"
  >
    <form-kit
      :type="passwordVisible ? 'text' : 'password'"
      name="password"
      inner-class="relative top-0 left-0"
      :label="`${$t.labels.password} *`"
      :placeholder="$t.labels.type_here"
      validation="required|length:6,10"
      validation-visibility="dirty"
      maxlength="10"
      :help="useConfirm ? $t.texts.password_help : undefined"
    >
      <template #suffix>
        <div
          class="absolute z-2 top-0 bottom-0 -right-4 left-auto flex justify-center items-center w-12 h-full"
        >
          <btn-icon
            size="regular"
            :icon="passwordVisible ? 'password-hidden' : 'password-visible'"
            @click.stop.prevent="passwordVisible = !passwordVisible"
            :title="$t.labels.password"
          />
        </div>
      </template>
    </form-kit>

    <form-kit
      :type="passwordVisible ? 'text' : 'password'"
      name="password_confirm"
      inner-class="relative top-0 left-0"
      :label="`${$t.labels.password_confirm} *`"
      :placeholder="$t.labels.type_here"
      validation="required|confirm"
      validation-visibility="dirty"
      :validation-label="$t.labels.password_confirm"
      maxlength="10"
    >
      <template #suffix>
        <div
          class="absolute z-2 top-0 bottom-0 -right-4 left-auto flex justify-center items-center w-12 h-full"
        >
          <btn-icon
            size="regular"
            :icon="passwordVisible ? 'password-hidden' : 'password-visible'"
            @click.stop.prevent="passwordVisible = !passwordVisible"
            :title="$t.labels.password"
          />
        </div>
      </template>
    </form-kit>
  </form-kit>

  <form-kit
    v-else
    component-name="input-password"
    :type="passwordVisible ? 'text' : 'password'"
    name="password"
    inner-class="relative top-0 left-0"
    :label="`${$t.labels.password} *`"
    :placeholder="$t.labels.type_here"
    validation="required|length:6,10"
    validation-visibility="dirty"
    maxlength="10"
  >
    <template #suffix>
      <div
        class="absolute z-2 top-0 bottom-0 -right-4 left-auto flex justify-center items-center w-12 h-full"
      >
        <btn-icon
          size="regular"
          :icon="passwordVisible ? 'password-hidden' : 'password-visible'"
          @click.stop.prevent="passwordVisible = !passwordVisible"
          :title="$t.labels.password"
          style="max-width: 16px"
        />
      </div>
    </template>
  </form-kit>
</template>

<script setup>
const props = defineProps({
  useConfirm: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const passwordVisible = ref(false);

const handleIconClick = (node, e) => {
  node.props.suffixIcon = node.props.suffixIcon === "eye" ? "eyeClosed" : "eye";
  node.props.type = node.props.type === "password" ? "text" : "password";
};
</script>

<style>
[component-name="input-password"].formkit-suffix-icon:hover {
  opacity: 0.5;
}
</style>
