<template>
  <component
    component-name="media"
    ref="tpl_root"
    :is="tag"
    class="block"
    data-skin="black"
    :class="{
      'aspect-4/5': aspectRatio === '4 / 5',
      'aspect-1/1': aspectRatio === '1 / 1',
      'aspect-3/1': aspectRatio === '3 / 1',
      'aspect-3/2': aspectRatio === '3 / 2',
      'aspect-16/9': aspectRatio === '16 / 9',
      [reveal]: reveal,
    }"
    v-surfer="reveal ? surfer : undefined"
  >
    <!-- VARIANT STATIC -->
    <picture v-if="computedVariant === 'static'">
      <source
        v-if="sourcesPortrait"
        :srcset="sourcesPortrait.srcset"
        :sizes="sourcesPortrait.sizes ? sourcesPortrait.sizes : undefined"
        media="(orientation: portrait)"
        class="contents"
      />
      <img
        ref="tpl_img"
        :data-loaded="imgLoaded"
        :loading="loading"
        :fetchpriority="fetchpriority"
        :crossorigin="
          /^thron$|^cloudinary$/.test(cdn) ? 'crossorigin' : undefined
        "
        inert
        draggable="false"
        :alt="showTitle ? alt : undefined"
        :src="
          isPortrait && sourcesPortrait?.base
            ? sourcesPortrait?.base || undefined
            : sources.base || undefined
        "
        :srcset="sources?.srcset || undefined"
        :sizes="sources?.sizes || undefined"
        :width="width || undefined"
        :height="height || undefined"
        class="pointer-events-none leading-none w-full h-full max-w-none max-h-none"
        :class="{
          'object-cover': fit === 'cover',
          'object-contain': fit === 'contain',
          'object-center': position === 'center',
          'object-top': position === 'top',
          'object-right': position === 'right',
          'object-bottom': position === 'bottom',
          'object-left': position === 'left',
          'bg-skin-base': useBg && !imgLoaded,
        }"
      />
    </picture>
    <!-- end VARIANT STATIC -->

    <!-- VARIANT MOTION -->
    <video
      v-else-if="computedVariant === 'motion'"
      crossorigin
      ref="tpl_video"
      inert
      v-surfer="surferPlayback"
      @surfer-visible-partially="onVideoIntersecting"
      @surfer-offscreen="onVideoOffscreen"
      @mouseover="showTitle = false"
      @mouseleave="showTitle = true"
      @canplay="videoLoaded = true"
      :title="showTitle ? alt : undefined"
      :width="width || undefined"
      :height="height || undefined"
      :preload="preload"
      :fetchpriority="fetchpriority"
      class="leading-none w-full h-full max-w-none max-h-none"
      :class="{
        'object-cover': fit === 'cover',
        'object-contain': fit === 'contain',
        'object-center': position === 'center',
        'object-top': position === 'top',
        'object-right': position === 'right',
        'object-bottom': position === 'bottom',
        'object-left': position === 'left',
        'bg-skin-base': useBg && !videoLoaded,
      }"
      disablePictureInPicture
      disableremoteplayback
      x-webkit-airplay="deny"
      controlsList="nodownload nofullscreen noremoteplayback"
      muted
      playsinline
      loop
    >
      <source :src="videoSrc" class="contents" />
    </video>
    <!-- end VARIANT MOTION -->
  </component>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: false,
    validator: (value) => /^span$|^div$/.test(value),
    default: "span",
  },
  originId: {
    type: String,
    required: false,
  },
  variant: {
    type: String,
    required: true,
    validator: (value) => /^static$|^motion$/.test(value),
    default: "static",
  },
  alt: {
    type: true,
    required: false,
  },
  cdn: {
    type: String,
    required: false,
    validator: (value) => /^none$|^thron$|^cloudinary$/.test(value),
    default: "none",
  },
  src: {
    type: String,
    required: true,
  },
  loading: {
    type: String,
    required: false,
    validator: (value) => /^auto$|^lazy$|^eager$/.test(value),
    default: "auto",
  },
  width: {
    type: [Number, String],
    required: false,
  },
  height: {
    type: [Number, String],
    required: false,
  },
  aspectRatio: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        ["none", "4 / 5", "1 / 1", "3 / 1", "3 / 2", "16 / 9"].indexOf(
          value
        ) !== -1
      );
    },
    default: "none",
  },
  useBg: {
    type: Boolean,
    required: false,
    default: true,
  },
  fit: {
    type: String,
    required: false,
    validator: (value) => /^cover$|^contain$/.test(value),
    default: "cover",
  },
  position: {
    type: String,
    required: false,
    validator: (value) => /^center$|^top$|^right$|^bottom$|^left$/.test(value),
    default: "center",
  },
  fetchpriority: {
    type: String,
    required: false,
    validator: (value) => /^auto$|^high$|^low$/.test(value),
    default: "auto",
  },
  reveal: {
    type: String,
    required: false,
  },
  revealMobile: {
    type: [Boolean, String],
    required: false,
    default: true,
  },
  idleMotionPolicy: {
    type: String,
    required: false,
    validator: (value) => /^pause$|^stop$/.test(value),
    default: "pause",
  },
  srcset: {
    required: false,
  },
  sizes: {
    required: false,
  },
  portrait: {
    type: Object,
    required: false,
  },
  pretty_name: {
    type: String,
    required: false,
  },
  thronConfig: {
    type: Object,
    required: false,
  },
});
const { isMobile } = useDevice();
const showTitle = ref(true);
const tpl_img = templateRef("tpl_img");
const tpl_video = templateRef("tpl_video");
const isPortrait = useMediaQuery("(orientation: portrait)");
const videoSrc = ref(props.src);
const intersecting = ref(false);
const breakpoints = ref();
const { fromCloudinary, fromThron } = useCdn();
const { screens } = useTailwindCss();
const { width: windowWidth } = useWindowSize();
const sources = computed(() => {
  return getMediaSources(props.src, props.cdn, props.sizes, props.srcset);
});

const imgLoaded = ref(false);
const videoLoaded = ref(false);

const sourcesPortrait = computed(() => {
  if (!props.portrait?.src) {
    return undefined;
  }
  return getMediaSources(
    props.portrait.src,
    props.portrait.cdn,
    props.portrait.sizes || props.sizes,
    props.portrait.srcset
  );
});

const getMediaSources = (src, cdn, sizes, srcset) => {
  if (cdn === "cloudinary") {
    return getCloudinarySrcset(src, sizes);
  } else if (cdn === "thron") {
    return getThronSrcset(src, sizes);
  }

  return {
    base: src,
    srcset: srcset,
    sizes: sizes,
  };
};

const preload = ref("none");
if (props.loading === "eager") preload.value = "metadata";
else if (props.loading !== "lazy") preload.value = "auto";

function getOrderedScreens() {
  const orderedScreens = [];

  const orderedQueries = [
    "phablet",
    "tablet",
    "laptop",
    "desktop",
    "extdesktop",
  ];

  for (let i = 0; i < orderedQueries.length; i++) {
    orderedScreens.push({
      prop: orderedQueries[i],
      value: parseInt(screens.value[orderedQueries[i]].min.replace("px", "")),
    });
  }

  return orderedScreens;
}

function getThronSrcset(url, queries) {
  if (!queries) {
    return {
      base: fromThron({
        contentId: url,
        prettyName: props.pretty_name || url,
        divArea: props.width,
        contentType: props.thronConfig?.contentType,
      }),
    };
  }

  const orderedScreens = getOrderedScreens();
  const srcset = [];
  const sizes = [];

  for (let i = 0; i < orderedScreens.length; i++) {
    if (queries[orderedScreens[i].prop]) {
      srcset.push(
        `${fromThron({
          contentId: url,
          prettyName: props.pretty_name || url,
          contentType: props.thronConfig?.contentType,
          divArea: /1 \/ 1|4 \/ 5|1 \/ 1|3 \/ 1|3 \/ 2|16 \/ 9/.test(
            props.aspectRatio
          )
            ? {
                width: queries[orderedScreens[i].prop],
                ar: props.aspectRatio,
              }
            : queries[orderedScreens[i].prop],
        })} ${orderedScreens[i].value}w`
      );

      sizes.push(
        `(min-width: ${orderedScreens[i].value}px) ${
          queries[orderedScreens[i].prop]
        }w`
      );
    }
  }

  return {
    base: fromThron({
      contentId: url,
      prettyName: props.pretty_name || url,
      divArea: /1 \/ 1|4 \/ 5|1 \/ 1|3 \/ 1|3 \/ 2|16 \/ 9/.test(
        props.aspectRatio
      )
        ? {
            width: props.sizes.base,
            ar: props.aspectRatio,
          }
        : props.sizes.base,
    }),
    srcset: srcset.join(", "),
    sizes: sizes.join(", "),
    contentType: props.thronConfig?.contentType,
  };
}

function getCloudinarySrcset(url, queries) {
  if (!queries) {
    return {
      base: url,
    };
  }

  const orderedScreens = getOrderedScreens();
  const srcset = [];
  const sizes = [];

  for (let i = 0; i < orderedScreens.length; i++) {
    if (queries[orderedScreens[i].prop]) {
      srcset.push(
        `${fromCloudinary(url, queries[orderedScreens[i].prop])} ${
          orderedScreens[i].value
        }w`
      );

      sizes.push(
        `(min-width: ${orderedScreens[i].value}px) ${
          queries[orderedScreens[i].prop]
        }w`
      );
    }
  }

  return {
    base: fromCloudinary(url, props.sizes.base),
    srcset: srcset.join(", "),
    sizes: sizes.join(", "),
  };
}

function initMotionMode() {
  if (props.sizes) {
    breakpoints.value = useBreakpoints(props.sizes);
  }

  setVideoSrc();
}

function onVideoIntersecting() {
  if (!tpl_video.value) {
    return;
  }
  intersecting.value = true;
  tpl_video.value.play();
}

function onVideoOffscreen() {
  intersecting.value = false;
  if (!tpl_video.value) {
    return;
  }

  if (tpl_video.value.paused) {
    tpl_video.value.play().then(() => {
      tpl_video.value.pause();
      if (props.idleMotionPolicy === "stop") {
        tpl_video.value.currentTime = 0;
      }
    });
  } else {
    tpl_video.value.pause();
    if (props.idleMotionPolicy === "stop") {
      tpl_video.value.currentTime = 0;
    }
  }
}

function setVideoSrc() {
  if (props.cdn === "cloudinary") {
    let src, sizes;
    if (computedVariant.value === "motion") {
      src =
        isPortrait.value && props.portrait?.src
          ? props.portrait.src
          : props.src;
      sizes =
        isPortrait.value && props.portrait?.sizes
          ? props.portrait.sizes
          : props.sizes;
      const screens = getOrderedScreens().reverse();
      let currentMq = { width: 1920 };

      if (sizes) {
        for (let i = 0; i < screens.length; i++) {
          if (windowWidth.value >= screens[i].value) {
            let mq = sizes[screens[i].prop];
            currentMq = typeof mq == "number" ? { width: mq } : mq;
            break;
          }
        }
      }

      videoSrc.value = fromCloudinary(src, currentMq);
    }
  } else if (props.cdn === "thron") {
    videoSrc.value = fromThron({
      src: props.src,
      type: "video",
      quality: "WEBHD" /*  WEB, WEBHD o WEBFULLHD */,
    });
  } else {
    videoSrc.value = props.src;
  }

  if (videoSrc.value) {
    nextTick(() => {
      tpl_video.value.load();
      if (intersecting.value) {
        tpl_video.value.play();
      }
    });
  }
}

const computedVariant = computed(() => {
  if (isPortrait.value && props.portrait) {
    return props.portrait.variant || props.variant;
  } else {
    return props.variant;
  }
});

const surferPlayback = computed(() => {
  if (computedVariant.value === "motion") {
    const obj = {
      setup: {
        debug: true,
      },
      observers: {
        init: true,
        visiblePartially: { event: true },
        offscreen: { event: true },
      },
    };
    return obj;
  } else return undefined;
});

const surfer = computed(() => {
  if (props.reveal) {
    const obj = {
      setup: {
        destroyOn: "visible",
      },
      observers: {
        init: true,
        visible: true,
        offscreenTop: true,
      },
    };
    return obj;
  } else return undefined;
});

/* HANDLE ON MOUNTED */
onMounted(() => {
  if (computedVariant.value === "static") {
    // avoid wrong behaviour on portrait/landscape variant value change
    videoLoaded.value = true;

    // useSafeMountedEl on image
    useSafeMountedEl(
      tpl_img,
      () => {
        if (tpl_img.value.naturalWidth) {
          imgLoaded.value = true;
        } else {
          tpl_img.value.addEventListener(
            "load",
            () => (imgLoaded.value = true),
            {
              once: true,
            }
          );
        }
      },
      false
    );
  } else if (computedVariant.value === "motion") {
    // avoid wrong behaviour on portrait/landscape variant value change
    imgLoaded.value = true;

    // useSafeMountedEl on video
    useSafeMountedEl(
      tpl_video,
      () => {
        watchThrottled(
          () => [windowWidth.value, isPortrait.value],
          () => {
            if (computedVariant.value === "motion") {
              initMotionMode();
            }
          },
          { throttle: 600, trailing: true, leading: false }
        );

        initMotionMode();
      },
      false
    );
  }
});
/* end HANDLE ON MOUNTED */
</script>
