<template>
  <span
    inert
    component-name="hover-icon"
    class="overflow-hidden block relative top-0 min-w-min w-min max-w-min"
  >
    <svg-icon
      ref="tpl_icon_top"
      class="relative top-0 left-0"
      :icon="$attrs.icon"
      :size="$attrs.size"
    />

    <svg-icon
      ref="tpl_icon_bottom"
      class="absolute top-0 left-0 invisible"
      :icon="$attrs.icon"
      :size="$attrs.size"
    />
  </span>
</template>

<script setup>
const attrs = useAttrs();
const tpl_icon_top = ref("tpl_icon_top");
const tpl_icon_bottom = ref("tpl_icon_bottom");
const isAnimating = ref(false);

const animationParams = computed(() => {
  const obj = { top: { from: {}, to: {} }, bottom: { from: {}, to: {} } };
  switch (attrs.icon) {
    case "arrow-long-right":
      obj.top.from.xPercent = 0;
      obj.top.to.xPercent = 200;
      obj.bottom.from.xPercent = -200;
      obj.bottom.to.xPercent = 0;
      break;

    case "arrow-long-left":
    case "angle-left":
      obj.top.from.xPercent = 0;
      obj.top.to.xPercent = -200;
      obj.bottom.from.xPercent = 200;
      obj.bottom.to.xPercent = 0;
      break;

    case "arrow-long-down":
    case "angle-down":
    case "download":
      obj.top.from.yPercent = 0;
      obj.top.to.yPercent = 200;
      obj.bottom.from.yPercent = -200;
      obj.bottom.to.yPercent = 0;
      break;

    default:
      obj.top.from.yPercent = 0;
      obj.top.to.yPercent = -200;
      obj.bottom.from.yPercent = 200;
      obj.bottom.to.yPercent = 0;
      break;
  }

  return obj;
});

let tl;

function animate(duration = 0.65, ease = "expo.inOut") {
  if (isAnimating.value) return;
  isAnimating.value = true;

  if (tl) {
    tl.kill();
    tl = null;
  }

  tl = gsap.timeline({
    onComplete: () => {
      if (tpl_icon_top.value?.$el)
        gsap.set(tpl_icon_top.value.$el, { yPercent: 0, xPercent: 0 });

      if (tpl_icon_bottom.value?.$el)
        gsap.set(tpl_icon_bottom.value.$el, {
          visibility: "hidden",
          yPercent: 0,
          xPercent: 0,
        });

      isAnimating.value = false;
    },
  });

  if (tpl_icon_top.value?.$el) {
    tl.fromTo(
      tpl_icon_top.value.$el,
      animationParams.value.top.from,
      {
        ...animationParams.value.top.to,
        duration,
        ease,
      },
      0
    );
  }

  if (tpl_icon_bottom.value?.$el) {
    tl.fromTo(
      tpl_icon_bottom.value.$el,
      animationParams.value.bottom.from,
      {
        ...animationParams.value.bottom.to,
        visibility: "visible",
        duration,
        ease,
      },
      0
    );
  }
}

defineExpose({
  animate,
});

onBeforeUnmount(() => {
  if (tl) {
    tl.kill();
    tl = null;
  }
});
</script>
