import { VueReCaptcha, useReCaptcha } from "vue-recaptcha-v3";

export const useGoogleReCaptcha = () => {
  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
  async function getRecaptcha() {
    // (optional) Wait until recaptcha has been loaded.
    await recaptchaLoaded();
    // Execute reCAPTCHA with action "login".
    const token = await executeRecaptcha();
    return token;
  }

  return {
    getRecaptcha,
  };
};
