<template>
  <details component-name="collapse" ref="tpl_root" class="flex flex-col">
    <slot name="header" :title="title" :icons="icons" :isOpen="isOpen" />

    <div class="px-md py-lg" :class="{ '-mt-px': title }">
      <slot />
    </div>
  </details>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  iconsType: {
    type: String,
    required: false,
    validator: function (value) {
      return ["arrow", "math"].indexOf(value) !== -1;
    },
    default: "arrow",
  },
});

const emit = defineEmits(["update"]);

const isOpen = ref(false);
const tpl_root = ref(null);

const icons = computed(() => {
  const i = {};

  switch (props.iconsType) {
    case "math":
      i.opened = "minus";
      i.closed = "plus";
      break;

    default:
      i.opened = "angle-up";
      i.closed = "angle-down";
      break;
  }

  return i;
});

useMutationObserver(
  tpl_root,
  (mutations) => {
    isOpen.value = mutations[0].oldValue === null;
  },
  {
    attributes: true,
    attributeFilter: ["open"],
    attributeOldValue: true,
  }
);

watch(isOpen, () => {
  ScrollTrigger.refresh(true);
  emit("update", isOpen.value);
});

useSafeMountedEl(tpl_root, () => {
  isOpen.value = tpl_root.value.hasAttribute("open");
});
</script>
