<template>
  <btn
    component-name="btn-solid"
    ref="tpl_root"
    class="flex flex-col max-w-full"
  >
    <div
      ref="tpl_wrapper"
      class="relative top-0 left-0 flex justify-start items-center gap-lg transition-colors duration-300 ease-in-out w-full max-w-full"
      :class="{
        'text-skin-inverse': isHover,
        'text-skin-base': !isHover,
        'px-lg': forceMiniPx || $attrs.size !== 'huge',
        'px-edge': !forceMiniPx && $attrs.size === 'huge',
        'py-2xl': $attrs.size === 'huge' || $attrs.size === 'big',
        'py-xl': !$attrs.size || $attrs.size === 'regular',
        'py-lg': $attrs.size === 'small',
      }"
    >
      <hover-rect
        :direction="hoverDirection"
        :hover="isHover"
        class="absolute inset-0 w-full h-full"
      />

      <hover-icon
        v-if="iconLeft"
        :icon="iconLeft"
        :size="$attrs.size === 'small' ? 'big' : 'huge'"
        class="relative top-0 left-0 z-2 text-current text-left"
      />

      <div
        class="relative top-0 left-0 z-2 text-current text-left w-full subpixel-antialiased"
      >
        <div
          :class="{
            'break-all': breakAll && multiline,
            'truncate leading-none': !multiline,
            'text-heading-4': $attrs.size === 'huge',
            'text-heading-6': $attrs.size === 'big',
            'text-button-big': $attrs.size === 'regular',
            'text-button': $attrs.size === 'small',
          }"
          v-html="$attrs.title"
        />

        <div
          v-if="text"
          class="subpixel-antialiased"
          :class="{
            'break-all': breakAll && multiline,
            'truncate leading-none': !multiline,
            'text-body-big': $attrs.size === 'huge',
            'text-body': $attrs.size === 'big',
            'text-body-small':
              $attrs.size === 'regular' || $attrs.size === 'small',
          }"
          v-html="text"
        />
      </div>

      <hover-icon
        v-if="iconRight"
        ref="tpl_hoverRightIcon"
        :icon="iconRight"
        :size="$attrs.size === 'small' ? 'big' : 'huge'"
        class="flex-none relative top-0 left-0 z-2 text-current"
      />
    </div>
  </btn>
</template>

<script setup>
const props = defineProps({
  iconLeft: {
    type: String,
    required: false,
  },
  iconRight: {
    type: String,
    required: false,
  },
  multiline: {
    type: Boolean,
    required: false,
    default: true,
  },
  breakAll: {
    type: Boolean,
    required: false,
  },
  text: {
    type: String,
    required: false,
  },
  forceMiniPx: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const attrs = useAttrs();
const tpl_root = templateRef("tpl_root");
const tpl_wrapper = templateRef("tpl_wrapper");
const tpl_hoverRightIcon = templateRef("tpl_hoverRightIcon");
const isVisible = ref(false);

const iconsize = computed(() => {
  let s;
  switch (attrs.size) {
    case "huge":
      s = "huge";
      break;

    case "big":
      s = "big";
      break;

    case "regular":
      s = "big";
      break;

    case "small":
      s = "regular";
      break;
  }

  return s;
});

const { isDesktop } = useDevice();
const hoverDirection = ref("top");
const isHover = ref(false);

// const { elementY, elementHeight, isOutside } = useMouseInElement(tpl_root, {
//   scroll: false,
// });

if (isDesktop) {
  // watch(isOutside, (newVal) => {
  //   hoverDirection.value =
  //     elementY.value > elementHeight.value / 2 ? "bottom" : "top";
  //   isHover.value = newVal ? false : true;
  // });

  // watch(isHover, (newVal) => {
  //   if (props.iconRight && newVal) tpl_hoverRightIcon.value.animate();
  // });

  onMounted(() => {
    tpl_wrapper.value.addEventListener("mouseenter", animateTarget);
    tpl_wrapper.value.addEventListener("mouseleave", animateTarget);
  });

  onBeforeUnmount(() => {
    tpl_wrapper.value.removeEventListener("mouseenter", animateTarget);
    tpl_wrapper.value.removeEventListener("mouseleave", animateTarget);
  });

  function animateTarget(event) {
    hoverDirection.value =
      event.offsetY > tpl_wrapper.value.clientHeight / 2 ? "bottom" : "top";

    if (event.type === "mouseenter") {
      if (props.iconRight) {
        tpl_hoverRightIcon.value.animate();
      }

      isHover.value = true;
    } else {
      isHover.value = false;
    }
  }
}
</script>
