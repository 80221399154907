import { defineStore } from "pinia";

export const useGeolistStore = defineStore("geolist", () => {
  const route = useRoute();

  const _countries = ref([]);

  const _optionsProvincesIT = ref([
    { label: "Agrigento", value: "AG" },
    { label: "Alessandria", value: "AL" },
    { label: "Ancona", value: "AN" },
    { label: "Aosta", value: "AO" },
    { label: "Ascoli Piceno", value: "AP" },
    { label: "L'Aquila", value: "AQ" },
    { label: "Arezzo", value: "AR" },
    { label: "Asti", value: "AT" },
    { label: "Avellino", value: "AV" },
    { label: "Bari", value: "BA" },
    { label: "Bergamo", value: "BG" },
    { label: "Biella", value: "BI" },
    { label: "Belluno", value: "BL" },
    { label: "Biella", value: "BI" },
    { label: "Benevento", value: "BN" },
    { label: "Bologna", value: "BO" },
    { label: "Brindisi", value: "BR" },
    { label: "Brescia", value: "BS" },
    { label: "Barletta-Andria-Trani", value: "BT" },
    { label: "Bolzano", value: "BZ" },
    { label: "Cagliari", value: "CA" },
    { label: "Campobasso", value: "CB" },
    { label: "Caserta", value: "CE" },
    { label: "Chieti", value: "CH" },
    { label: "Carbonia-Iglesias", value: "CI" },
    { label: "Caltanissetta", value: "CL" },
    { label: "Cuneo", value: "CN" },
    { label: "Como", value: "CO" },
    { label: "Cremona", value: "CR" },
    { label: "Cosenza", value: "CS" },
    { label: "Catania", value: "CT" },
    { label: "Catanzaro", value: "CZ" },
    { label: "Enna", value: "EN" },
    { label: "Forli-Cesena", value: "FC" },
    { label: "Ferrara", value: "FE" },
    { label: "Foggia", value: "FG" },
    { label: "Firenze", value: "FI" },
    { label: "Fermo", value: "FM" },
    { label: "Frosinone", value: "FR" },
    { label: "Genova", value: "GE" },
    { label: "Gorizia", value: "GO" },
    { label: "Grosseto", value: "GR" },
    { label: "Imperia", value: "IM" },
    { label: "Isernia", value: "IS" },
    { label: "Crotone", value: "KR" },
    { label: "Lecco", value: "LC" },
    { label: "Lecce", value: "LE" },
    { label: "Livorno", value: "LI" },
    { label: "Lodi", value: "LO" },
    { label: "Latina", value: "LT" },
    { label: "Lucca", value: "LU" },
    { label: "Monza e Brianza", value: "MB" },
    { label: "Macerata", value: "MC" },
    { label: "Messina", value: "ME" },
    { label: "Milano", value: "MI" },
    { label: "Mantova", value: "MN" },
    { label: "Modena", value: "MO" },
    { label: "Massa e Carrara", value: "MS" },
    { label: "Matera", value: "MT" },
    { label: "Napoli", value: "NA" },
    { label: "Novara", value: "NO" },
    { label: "Nuoro", value: "NU" },
    { label: "Ogliastra", value: "OG" },
    { label: "Oristano", value: "OR" },
    { label: "Olbia-Tempio", value: "OT" },
    { label: "Palermo", value: "PA" },
    { label: "Piacenza", value: "PC" },
    { label: "Padova", value: "PD" },
    { label: "Pescara", value: "PE" },
    { label: "Perugia", value: "PG" },
    { label: "Pisa", value: "PI" },
    { label: "Pordenone", value: "PN" },
    { label: "Prato", value: "PO" },
    { label: "Parma", value: "PR" },
    { label: "Pistoia", value: "PT" },
    { label: "Pesaro e Urbino", value: "PU" },
    { label: "Pavia", value: "PV" },
    { label: "Potenza", value: "PZ" },
    { label: "Ravenna", value: "RA" },
    { label: "Reggio Calabria", value: "RC" },
    { label: "Reggio Emilia", value: "RE" },
    { label: "Ragusa", value: "RG" },
    { label: "Rieti", value: "RI" },
    { label: "Roma", value: "RM" },
    { label: "Rimini", value: "RN" },
    { label: "Rovigo", value: "RO" },
    { label: "Salerno", value: "SA" },
    { label: "Siena", value: "SI" },
    { label: "Sondrio", value: "SO" },
    { label: "La Spezia", value: "SP" },
    { label: "Siracusa", value: "SR" },
    { label: "Sassari", value: "SS" },
    { label: "Savona", value: "SV" },
    { label: "Taranto", value: "TA" },
    { label: "Teramo", value: "TE" },
    { label: "Trento", value: "TN" },
    { label: "Torino", value: "TO" },
    { label: "Trapani", value: "TP" },
    { label: "Terni", value: "TR" },
    { label: "Trieste", value: "TS" },
    { label: "Treviso", value: "TV" },
    { label: "Udine", value: "UD" },
    { label: "Varese", value: "VA" },
    { label: "Verbano-Cusio-Ossola", value: "VB" },
    { label: "Vercelli", value: "VC" },
    { label: "Venezia", value: "VE" },
    { label: "Vicenza", value: "VI" },
    { label: "Verona", value: "VR" },
    { label: "Medio Campidano", value: "VS" },
    { label: "Viterbo", value: "VT" },
    { label: "Vibo Valentia", value: "VV" },
  ]);

  const _optionsStatesUS = ref([
    { label: "Alaska", value: "USAK" },
    { label: "Alabama", value: "USAL" },
    { label: "Arkansas", value: "USAR" },
    { label: "American Samoa", value: "USAS" },
    { label: "Arizona", value: "USAZ" },
    { label: "California", value: "USCA" },
    { label: "Colorado", value: "USCO" },
    { label: "Connecticut", value: "USCT" },
    { label: "District of Columbia", value: "USDC" },
    { label: "Delaware", value: "USDE" },
    { label: "Florida", value: "USFL" },
    { label: "Federated Micronesia", value: "USFM" },
    { label: "Georgia", value: "USGA" },
    { label: "Guam", value: "USGU" },
    { label: "Hawaii", value: "USHI" },
    { label: "Iowa", value: "USIA" },
    { label: "Idaho", value: "USID" },
    { label: "Illinois", value: "USIL" },
    { label: "Indiana", value: "USIN" },
    { label: "Kansas", value: "USKS" },
    { label: "Kentucky", value: "USKY" },
    { label: "Louisiana", value: "USLA" },
    { label: "Massachusetts", value: "USMA" },
    { label: "Maryland", value: "USMD" },
    { label: "Maine", value: "USME" },
    { label: "Marshall Islands", value: "USMH" },
    { label: "Michigan", value: "USMI" },
    { label: "Minnesota", value: "USMN" },
    { label: "Missouri", value: "USMO" },
    { label: "Northern Mariana Islands", value: "USMP" },
    { label: "Mississippi", value: "USMS" },
    { label: "Montana", value: "USMT" },
    { label: "North Carolina", value: "USNC" },
    { label: "North Dakota", value: "USND" },
    { label: "Nebraska", value: "USNE" },
    { label: "New Hampshire", value: "USNH" },
    { label: "New Jersey", value: "USNJ" },
    { label: "New Mexico", value: "USNM" },
    { label: "Nevada", value: "USNV" },
    { label: "New York", value: "USNY" },
    { label: "Ohio", value: "USOH" },
    { label: "Oklahoma", value: "USOK" },
    { label: "Oregon", value: "USOR" },
    { label: "Pennsylvania", value: "USPA" },
    { label: "Puerto Rico", value: "USPR" },
    { label: "Palau", value: "USPW" },
    { label: "Rhode Island", value: "USRI" },
    { label: "South Carolina", value: "USSC" },
    { label: "South Dakota", value: "USSD" },
    { label: "Tennessee", value: "USTN" },
    { label: "Texas", value: "USTX" },
    { label: "United States Minor Outlying Islands", value: "USUM" },
    { label: "Utah", value: "USUT" },
    { label: "Virginia", value: "USVA" },
    { label: "US Virgin Islands", value: "USVI" },
    { label: "Vermont", value: "USVT" },
    { label: "Washington", value: "USWA" },
    { label: "Wisconsin", value: "USWI" },
    { label: "West Virginia", value: "USWV" },
    { label: "Wyoming", value: "USWY" },
  ]);

  async function load() {
    const response = await $fetch(`/api/geolist/?lng=${route.params.locale}`);
    _countries.value = response || [];
  }

  function _getCountryLabelByValue(value) {
    let label = "";

    for (let i = 0, len = _countries.value.length; i < len; i++) {
      if (_countries.value[i].value === value) {
        label = _countries.value[i].label;
        break;
      }
    }

    return label;
  }

  function _getStateLabelByValue(countryValue, value) {
    let label = "";

    if (countryValue === "IT") {
      for (let i = 0, len = _optionsProvincesIT.value.length; i < len; i++) {
        if (_optionsProvincesIT.value[i].value === value) {
          label = _optionsProvincesIT.value[i].label;
          break;
        }
      }
    } else if (countryValue === "US") {
      for (let i = 0, len = _optionsStatesUS.value.length; i < len; i++) {
        if (_optionsStatesUS.value[i].value === value) {
          label = _optionsStatesUS.value[i].label;
          break;
        }
      }
    }

    return label;
  }

  function getCountryLabelByISO(value) {
    return (
      _countries.value.find(
        (country) => country.value.toUpperCase() === value.toUpperCase()
      )?.label || value
    );
  }

  return {
    load,
    countries: computed(() => _countries.value),
    provincesIt: computed(() => _optionsProvincesIT.value),
    statesUs: computed(() => _optionsStatesUS.value),
    getCountryLabel: (value) => _getCountryLabelByValue(value),
    getStateLabel: (countryCode, value) =>
      _getStateLabelByValue(countryCode, value),
    getCountryLabelByISO,
  };
});
