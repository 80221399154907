<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-web-to-lead"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="modal-backdrop"
            aria-hidden="true"
            @click="emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex items-stretch justify-end w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto overflow-y-auto w-[calc(100vw-60px)] max-w-[570px] h-full max-h-full bg-skin-base dark:bg-skin-muted p-3xl"
              >
                <transition-fade>
                  <div
                    v-if="webToLeadFormVariant"
                    key="web-to-lead"
                    class="flex flex-col items-start gap-xl"
                  >
                    <btn-nav
                      icon-left="angle-left"
                      :title="$t.labels.back"
                      @click="webToLeadFormVariant = null"
                      class="focus:outline-none"
                    />

                    <divider class="w-full" />

                    <form-web-to-lead
                      v-if="webToLeadFormVariant"
                      class="w-full"
                      :user="webToLeadFormVariant.user.value"
                      :variant="webToLeadFormVariant.variant"
                      :minimize-personal-info="true"
                      :show-support-type="true"
                      :use-subject="true"
                      @alert-dismissed="isOpen = false"
                    />
                  </div>

                  <div v-else key="picker" class="flex flex-col gap-3xl w-full">
                    <txt-heading
                      size="small"
                      :overline="$t.labels.support"
                      :title="$t.titles.contextual_info_request"
                    />

                    <!-- SUBJECT & SUPPORT TYPE -->

                    <div class="flex flex-col gap-2xl w-full">
                      <input-static
                        v-if="userStore.logged"
                        :label="$t.labels.name"
                        :text="`${userStore.user.first_name} ${userStore.user.last_name}`"
                      />

                      <input-static
                        :label="$t.labels.subject"
                        :text="pageTitle"
                      />
                    </div>

                    <!-- end SUBJECT & SUPPORT TYPE -->

                    <form-web-to-lead-picker
                      scope="value"
                      submitBtnSize="regular"
                      @webToLead="setWebToLeadVariant"
                    />
                  </div>
                </transition-fade>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";
import { useUserStore } from "@/stores/user";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const pageTitle = ref("");
const webToLeadFormVariant = ref();
const route = useRoute();
const emit = defineEmits(["close"]);
const userStore = useUserStore();
const nuxtApp = useNuxtApp();

function setWebToLeadVariant(data) {
  webToLeadFormVariant.value =
    data?.user && data?.variant
      ? {
          user: data.user,
          variant: data.variant,
        }
      : null;
}

watch(route, (newVal, oldVal) => {
  emit("close");
  webToLeadFormVariant.value = null;
});

onMounted(() => {
  setTimeout(() => {
    pageTitle.value = document.title.replace(
      nuxtApp.$t.labels.brand_suffix,
      ""
    );
  }, 10);
});
</script>
