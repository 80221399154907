<template>
  <div
    component-name="txt-prose"
    class="prose max-w-full text-body-big text-skin-base"
    :class="classes"
  >
    <div v-if="html" class="contents" v-html="html"></div>
    <slot v-else />
  </div>
</template>

<script setup>
const props = defineProps({
  html: {
    type: String,
    required: false,
  },
});

const classes = computed(() => {
  /* HEADINGS */
  const h1 = "prose-h1:text-heading-1 prose-h1:text-skin-base";
  const h2 = "prose-h2:text-heading-2 prose-h2:text-skin-base";
  const h3 = "prose-h3:text-heading-3 prose-h3:text-skin-base";
  const h4 = "prose-h4:text-heading-4 prose-h4:text-skin-base";
  const h5 = "prose-h5:text-heading-5 prose-h5:text-skin-base";
  const h6 = "prose-h6:text-heading-6 prose-h6:text-skin-base";
  /* end HEADINGS */

  /* TEXTS */
  const p = "prose-p:text-body-big";
  const strong = "prose-strong:text-skin-base prose-strong:text-strong";
  const a =
    "prose-a:text-skin-muted prose-a:underline prose-a:decoration-transparent prose-a:transition-[color,opacity,text-decoration] prose-a:duration-200 prose-a:ease-in-out hover:prose-a:text-skin-base hover:prose-a:decoration-current";
  const ul =
    "prose-ul:list-disc prose-ul:list-inside prose-ul:pl-0 marker:prose-ul:text-skin-base";
  const ol =
    "prose-ol:list-decimal prose-ol:list-inside prose-ol:pl-0 marker:prose-ol:text-skin-base";
  const blockquote =
    "prose-blockquote:text-body-big prose-blockquote:border-skin-subtle prose-blockquote:text-skin-base";
  /* end TEXTS */

  /* TABLES */
  const table = `
      prose-table:w-full prose-table:max-w-full
      prose-table:text-skin-base prose-table:bg-skin-base
      prose-table:border prose-table:border-current
    `;
  const thead = `prose-thead:w-full prose-thead:bg-skin-inverse/10`;
  const th = `prose-th:text-body-small prose-th:text-skin-base prose-th:border prose-th:border-current prose-th:p-sm`;
  const td = `prose-th:text-body-tiny prose-td:text-skin-base prose-td:border prose-td:border-current prose-td:p-sm`;
  const table_a = `prose-a:prose-table:opacity-80`;
  /* end TABLES */

  const headings = `${h1} ${h2} ${h3} ${h4} ${h5} ${h6}`;
  const texts = `${p} ${strong} ${a} ${ul} ${ol} ${blockquote}`;
  const tables = `${table} ${thead} ${td} ${th} ${table_a}`;

  return `${headings} ${texts} ${tables}`;
});
</script>

<style scoped>
.prose {
  @apply inline-block;

  & table tr br {
    @apply hidden;
  }
}
</style>
