<template>
  <div component-name="input-country" class="contents">
    <form-kit
      type="select"
      name="country_code"
      validation="required"
      :placeholder="$t.labels.select_option"
      :label="$t.labels.country"
      :options="geolistStore.countries"
      @input="onCountryChange"
      v-model="selectedCountry"
    />
    <input-state v-if="useStates && /IT|US/.test(selectedCountry)" :country-code="selectedCountry" />
  </div>
</template>

<script setup>
import { useGeolistStore } from "@/stores/geolist";

const props = defineProps({
  useStates: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const geolistStore = useGeolistStore();
const selectedCountry = ref();

watch([() => props.useProvinceIt, () => props.useStatesUs], () => {
  nextTick(() => {
    ScrollTrigger.refresh();
  });
});

function onCountryChange(country) {
  selectedCountry.value = country;
}
</script>
