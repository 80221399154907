<template>
  <article
    component-name="menu-nav"
    data-lenis-prevent
    class="overflow-y-auto overscroll-y-contain z-[1] flex flex-col gap-x-ui gap-y-2xl px-edge py-2xl bg-skin-base text-skin-base h-full max-h-full [&>*]:max-h-full"
  >
    <!-- PRIMARY NAVIGATION -->
    <nav class="flex flex-col w-full gap-y-2xl">
      <div class="flex flex-col items-start gap-md">
        <btn-nav
          size="huge"
          :title="$t.labels.products"
          @click="$emit('products')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'products',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.attr_pa_soluzioni"
          @click="$emit('solutions')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'solutions',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.atelier"
          :action="{ to: '/products/atelier' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'atelier',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.architects_and_designers"
          :action="{ to: '/architects-and-designers' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'architects and designers',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.customizers"
          @click="$emit('customizers')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'solutions',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.stories"
          :action="{ to: '/stories' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'stories',
          }"
        />
        <btn-nav
          size="huge"
          :title="$t.labels.mirage_world"
          @click="$emit('mirage_world')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'mirage world',
          }"
        />
      </div>

      <divider class="w-full" />

      <div class="flex flex-col items-start gap-md">
        <btn-nav
          icon-left="search"
          size="big"
          :title="$t.labels.search"
          :action="{ to: '/search' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'search',
          }"
        />

        <btn-nav
          v-if="storeLocatorEnabled"
          icon-left="store-locator"
          size="big"
          :title="$t.labels.store_locator"
          :action="{ to: '/store-locator' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'store locator',
          }"
        />

        <btn-nav
          v-if="userStore.logged"
          icon-left="user-logged"
          size="big"
          :title="$t.labels.account"
          :action="{ to: '/account/personal-area/moodboards' }"
        />

        <btn-nav
          v-else
          icon-left="user"
          size="big"
          :title="$t.labels.signin"
          :action="{ to: '/signin' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'signin',
          }"
        />
      </div>
    </nav>
    <!-- end PRIMARY NAVIGATION -->

    <divider class="w-full" />

    <!-- SECONDARY NAVIGATION -->
    <menu class="flex flex-col items-start list-none gap-md">
      <li>
        <btn-nav
          :title="$t.labels.professional"
          @click="$emit('professional')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'professional',
          }"
        />
      </li>

      <li>
        <btn-nav
          :title="$t.labels.resources"
          :action="{ to: '/resources' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'resources',
          }"
        />
      </li>

      <li>
        <btn-nav
          :title="$t.labels.press"
          :action="{ to: '/press' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'press',
          }"
        />
      </li>

      <li>
        <btn-nav
          :title="$t.labels.contacts"
          :action="{ to: '/contacts' }"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'contacts',
          }"
        />
      </li>

      <li>
        <btn-nav
          :title="$t.labels.change_market"
          icon-left="world"
          @click="$emit('locale')"
          :use-tracking="{
            event: 'click_voce_menu',
            voce_menu: 'change_market_language',
          }"
        />
      </li>
    </menu>
    <!-- end SECONDARY NAVIGATION -->
  </article>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const { storeLocatorEnabled } = useGlobals();
</script>
