const _none = "none"
const _all = "all"
const _DEFAULT = "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter"
const _colors = "color, background-color, border-color, text-decoration-color, fill, stroke"
const _opacity = "opacity"
const _shadow = "box-shadow"
const _transform = "transform"
const _fade = "transform, opacity, color"
const _clip = "clip-path"
const _reveal = "transform, opacity, clip-path"
const config = { "none": _none, "all": _all, "DEFAULT": _DEFAULT, "colors": _colors, "opacity": _opacity, "shadow": _shadow, "transform": _transform, "fade": _fade, "clip": _clip, "reveal": _reveal,  }
export { config as default, _none, _all, _DEFAULT, _colors, _opacity, _shadow, _transform, _fade, _clip, _reveal }