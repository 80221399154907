<template>
  <div component-name="input-state" class="contents">
    <form-kit
      type="select"
      name="state_code"
      :placeholder="$t.labels.select_option"
      :component-name="countryOptions.componentName"
      :label="countryOptions.label"
      :options="countryOptions.options"
      validation="required"
    />
  </div>
</template>

<script setup>
import { useGeolistStore } from "@/stores/geolist";

const props = defineProps({
  countryCode: {
    type: String,
    required: true,
    validator: function (value) {
      return ["IT", "US"].indexOf(value) !== -1;
    },
  },
});

const geolistStore = useGeolistStore();
const nuxtApp = useNuxtApp();

watch(
  () => props.countryCode,
  () => {
    nextTick(() => {
      ScrollTrigger.refresh();
    });
  }
);

const countryOptions = computed(() => {
  if (props.countryCode === "IT") {
    return {
      componentName: "input-province-it",
      options: geolistStore.provincesIt,
      label: nuxtApp.$t.labels.province,
    };
  } else if (props.countryCode === "US") {
    return {
      componentName: "input-state-us",
      options: geolistStore.statesUs,
      label: nuxtApp.$t.labels.state,
    };
  }
});
</script>
