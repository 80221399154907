const _0 = "0px"
const _1 = "0.25rem"
const _2 = "0.5rem"
const _3 = "0.75rem"
const _4 = "1rem"
const _5 = "1.25rem"
const _6 = "1.5rem"
const _7 = "1.75rem"
const _8 = "2rem"
const _9 = "2.25rem"
const _10 = "2.5rem"
const _11 = "2.75rem"
const _12 = "3rem"
const _13 = "52px"
const _14 = "3.5rem"
const _16 = "4rem"
const _20 = "5rem"
const _24 = "6rem"
const _27 = "108px"
const _28 = "7rem"
const _30 = "120px"
const _32 = "8rem"
const _36 = "9rem"
const _40 = "10rem"
const _44 = "11rem"
const _48 = "12rem"
const _52 = "13rem"
const _56 = "14rem"
const _60 = "15rem"
const _64 = "16rem"
const _72 = "18rem"
const _80 = "20rem"
const _96 = "24rem"
const _px = "1px"
const _edge = "clamp(24px, 4.16vw, 80px)"
const _2xs = "4px"
const _xs = "8px"
const _sm = "12px"
const _md = "16px"
const _lg = "clamp(20px, 1.25vw, 24px)"
const _xl = "clamp(24px, 1.67vw, 32px)"
const _2xl = "clamp(28px, 2.08vw, 40px)"
const _3xl = "clamp(48px, 4.16vw, 80px)"
const _4xl = "clamp(64px, 6.25vw, 120px)"
const _5xl = "clamp(104px, 10.42vw, 200px)"
const _ui = "clamp(12px, 2.08vw, 40px)"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "13": _13, "14": _14, "16": _16, "20": _20, "24": _24, "27": _27, "28": _28, "30": _30, "32": _32, "36": _36, "40": _40, "44": _44, "48": _48, "52": _52, "56": _56, "60": _60, "64": _64, "72": _72, "80": _80, "96": _96, "px": _px, "edge": _edge, "2xs": _2xs, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "ui": _ui, "0.5": "0.125rem", "1.5": "0.375rem", "2.5": "0.625rem", "3.5": "0.875rem", "ui-half": "clamp(6px, 1.04vw, 20px)",  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _16, _20, _24, _27, _28, _30, _32, _36, _40, _44, _48, _52, _56, _60, _64, _72, _80, _96, _px, _edge, _2xs, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _ui }