export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();

  /* 
  const {
    setStoreLocatorEnabled
  } = useGlobals();

  setStoreLocatorEnabled(runtimeConfig.public.storeLocatorEnabled === "true");
  */
});
