import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBlockAlert, LazyBlockCarouselAccent, LazyBlockCarouselCollapse, LazyBlockCarouselMultiratio, LazyBlockCarouselProducts, LazyBlockCarouselRich, LazyBlockCarouselSlides, LazyBlockCarouselSplitview, LazyBlockDivider, LazyBlockFormAccountActivated, LazyBlockFormPassword, LazyBlockFormSignin, LazyBlockFormSignup, LazyBlockFormStoreLocator, LazyBlockFormSupport, LazyBlockFormSupportPicker, LazyBlockGridBlog, LazyBlockGridCollections, LazyBlockGridContacts, LazyBlockGridDecors, LazyBlockGridMedia, LazyBlockGridMirageExperience, LazyBlockGridMoodboard, LazyBlockGridMoodboards, LazyBlockGridOl, LazyBlockGridPress, LazyBlockGridProductsSearch, LazyBlockGridProject, LazyBlockGridResourcesCollections, LazyBlockGridResources, LazyBlockGridSearch, LazyBlockGridThumbs, LazyBlockGridVariants, LazyBlock, LazyBlockLauncherBtnList, LazyBlockLauncherCtaDouble, LazyBlockLauncherCta, LazyBlockLauncherSlides, LazyBlockMedia, LazyBlockMediaVideoplayer, LazyBlockTextBtnList, LazyBlockTextChapter, LazyBlockTextChapterMediaCouple, LazyBlockTextChapterMedia, LazyBlockTextChapterRich, LazyBlockTextCollapse, LazyBlockTextCounter, LazyBlockTextCouple, LazyBlockTextHeading, LazyBlockTextOl, LazyBlockTextParagraphMedia, LazyBlockTextParagraphVideoplayer, LazyBlockTextProductDetails, LazyBlockTextProse, LazyBlockTextScream, LazyBlockToolbarCredits, LazyBlockToolbarMoodboard, LazyBlockToolbarUser, LazyBtnAddToMoodboard, LazyBtnArrow, LazyBtnChip, LazyBtnColorSwatch, LazyBtnCreateMoodboard, LazyBtnHandleFavourite, LazyBtnIcon, LazyBtn, LazyBtnIndoorOutdoor, LazyBtnList, LazyBtnMirage, LazyBtnNav, LazyBtnPill, LazyBtnSolid, LazyBtnTab } from '#components'
const lazyGlobalComponents = [
  ["BlockAlert", LazyBlockAlert],
["BlockCarouselAccent", LazyBlockCarouselAccent],
["BlockCarouselCollapse", LazyBlockCarouselCollapse],
["BlockCarouselMultiratio", LazyBlockCarouselMultiratio],
["BlockCarouselProducts", LazyBlockCarouselProducts],
["BlockCarouselRich", LazyBlockCarouselRich],
["BlockCarouselSlides", LazyBlockCarouselSlides],
["BlockCarouselSplitview", LazyBlockCarouselSplitview],
["BlockDivider", LazyBlockDivider],
["BlockFormAccountActivated", LazyBlockFormAccountActivated],
["BlockFormPassword", LazyBlockFormPassword],
["BlockFormSignin", LazyBlockFormSignin],
["BlockFormSignup", LazyBlockFormSignup],
["BlockFormStoreLocator", LazyBlockFormStoreLocator],
["BlockFormSupport", LazyBlockFormSupport],
["BlockFormSupportPicker", LazyBlockFormSupportPicker],
["BlockGridBlog", LazyBlockGridBlog],
["BlockGridCollections", LazyBlockGridCollections],
["BlockGridContacts", LazyBlockGridContacts],
["BlockGridDecors", LazyBlockGridDecors],
["BlockGridMedia", LazyBlockGridMedia],
["BlockGridMirageExperience", LazyBlockGridMirageExperience],
["BlockGridMoodboard", LazyBlockGridMoodboard],
["BlockGridMoodboards", LazyBlockGridMoodboards],
["BlockGridOl", LazyBlockGridOl],
["BlockGridPress", LazyBlockGridPress],
["BlockGridProductsSearch", LazyBlockGridProductsSearch],
["BlockGridProject", LazyBlockGridProject],
["BlockGridResourcesCollections", LazyBlockGridResourcesCollections],
["BlockGridResources", LazyBlockGridResources],
["BlockGridSearch", LazyBlockGridSearch],
["BlockGridThumbs", LazyBlockGridThumbs],
["BlockGridVariants", LazyBlockGridVariants],
["Block", LazyBlock],
["BlockLauncherBtnList", LazyBlockLauncherBtnList],
["BlockLauncherCtaDouble", LazyBlockLauncherCtaDouble],
["BlockLauncherCta", LazyBlockLauncherCta],
["BlockLauncherSlides", LazyBlockLauncherSlides],
["BlockMedia", LazyBlockMedia],
["BlockMediaVideoplayer", LazyBlockMediaVideoplayer],
["BlockTextBtnList", LazyBlockTextBtnList],
["BlockTextChapter", LazyBlockTextChapter],
["BlockTextChapterMediaCouple", LazyBlockTextChapterMediaCouple],
["BlockTextChapterMedia", LazyBlockTextChapterMedia],
["BlockTextChapterRich", LazyBlockTextChapterRich],
["BlockTextCollapse", LazyBlockTextCollapse],
["BlockTextCounter", LazyBlockTextCounter],
["BlockTextCouple", LazyBlockTextCouple],
["BlockTextHeading", LazyBlockTextHeading],
["BlockTextOl", LazyBlockTextOl],
["BlockTextParagraphMedia", LazyBlockTextParagraphMedia],
["BlockTextParagraphVideoplayer", LazyBlockTextParagraphVideoplayer],
["BlockTextProductDetails", LazyBlockTextProductDetails],
["BlockTextProse", LazyBlockTextProse],
["BlockTextScream", LazyBlockTextScream],
["BlockToolbarCredits", LazyBlockToolbarCredits],
["BlockToolbarMoodboard", LazyBlockToolbarMoodboard],
["BlockToolbarUser", LazyBlockToolbarUser],
["BtnAddToMoodboard", LazyBtnAddToMoodboard],
["BtnArrow", LazyBtnArrow],
["BtnChip", LazyBtnChip],
["BtnColorSwatch", LazyBtnColorSwatch],
["BtnCreateMoodboard", LazyBtnCreateMoodboard],
["BtnHandleFavourite", LazyBtnHandleFavourite],
["BtnIcon", LazyBtnIcon],
["Btn", LazyBtn],
["BtnIndoorOutdoor", LazyBtnIndoorOutdoor],
["BtnList", LazyBtnList],
["BtnMirage", LazyBtnMirage],
["BtnNav", LazyBtnNav],
["BtnPill", LazyBtnPill],
["BtnSolid", LazyBtnSolid],
["BtnTab", LazyBtnTab],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
