<template>
  <span
    component-name="hover-rect"
    ref="tpl_root"
    class="overflow-hidden grid grid-cols-1 [&>*]:cols-span-full [&>*]:row-start-1 [&>*]:row-end-2 [&>*]:w-full [&>*]:h-full [&>*]:bg-skin-inverse"
  ></span>
</template>

<script setup>
const props = defineProps({
  hover: {
    type: Boolean,
    required: false,
    default: false,
  },
  direction: {
    type: String,
    required: false,
    validator: function (value) {
      return ["top", "bottom"].indexOf(value) !== -1;
    },
    default: "top",
  },
});
const tpl_root = templateRef("tpl_root");

watch(
  () => props.hover,
  (newVal, oldVal) => {
    newVal ? doHover() : undoHover();
  }
);

function doHover() {
  const node = document.createElement("span");
  tpl_root.value.appendChild(node);
  const $el = tpl_root.value.children[tpl_root.value.children.length - 1];
  gsap.fromTo(
    $el,
    { yPercent: props.direction === "top" ? -100 : 100 },
    { yPercent: 0, duration: 0.22, ease: "none" }
  );
}

function undoHover() {
  const $el = tpl_root.value.children[tpl_root.value.children.length - 1];
  gsap.to($el, {
    yPercent: props.direction === "top" ? -100 : 100,
    duration: 0.22,
    ease: "none",
    onComplete: () => {
      $el.remove();
    },
  });
}
</script>
