<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-menu"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="fixed inset-0 bg-black/50"
            aria-hidden="true"
            @click="$emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div class="pointer-events-none overflow-clip fixed inset-0">
          <!-- Container to center the panel -->
          <div class="flex flex-col w-full h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-y-full"
              enter-to="translate-y-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-y-0"
              leave-to="translate-y-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                class="pointer-events-auto w-full h-screen pb-[calc(100lvh-100svh)] !m-0 !p-0 bg-skin-base"
              >
                <div class="flex flex-col w-full h-full max-h-full">
                  <toolbar-modal-header
                    :navigation="navigation"
                    @back="$emit('navigation', 'nav')"
                    @close="$emit('close')"
                    class="sticky z-2 top-0 left-0"
                  />
                  <div class="grow overflow-hidden w-full max-h-full">
                    <div
                      data-skin="white"
                      class="overflow-hidden relative top-0 left-0 grid grid-cols-1 w-full h-full max-h-full bg-skin-base text-skin-base [&>*]:relative [&>*]:top-0 [&>*]:left-0 [&>*]:row-start-1 [&>*]:row-end-2 [&>*]:col-span-full [&>*]:w-full"
                    >
                      <transition
                        mode="in-out"
                        :enter-active-class="
                          navigation !== 'nav'
                            ? 'transition-transform will-change-transform translate-x-full duration-300 ease-out !pointer-events-none'
                            : '!pointer-events-none z-[1]'
                        "
                        :enter-from-class="
                          navigation !== 'nav' ? 'translate-x-full' : ''
                        "
                        :enter-to-class="
                          navigation !== 'nav' ? '!translate-x-0' : ''
                        "
                        :leave-active-class="
                          navigation !== 'nav'
                            ? '!pointer-events-none z-[1]'
                            : 'transition-transform will-change-transform translate-x-0 duration-300 ease-in !pointer-events-none'
                        "
                        :leave-from-class="
                          navigation !== 'nav' ? '' : 'translate-x-0'
                        "
                        :leave-to-class="
                          navigation !== 'nav' ? '' : '!translate-x-full'
                        "
                      >
                        <!-- LOCALE -->
                        <menu-locale
                          v-if="navigation === 'locale'"
                          key="locale"
                          :data="rootStore.ui.modal.menu.locale"
                          class="z-2"
                        />
                        <!-- end LOCALE -->

                        <!-- PRODUCTS -->
                        <menu-products
                          v-else-if="navigation === 'products'"
                          key="products"
                          :data="rootStore.ui.modal.menu.products"
                          class="z-2"
                        />
                        <!-- end PRODUCTS -->

                        <!-- CUSTOMIZERS -->
                        <menu-customizers
                          v-else-if="navigation === 'customizers'"
                          key="customizers"
                          :data="rootStore.ui.modal.menu.customizers"
                          class="z-2"
                        />
                        <!-- end CUSTOMIZERS -->

                        <!-- SOLUTIONS -->
                        <menu-solutions
                          v-else-if="navigation === 'solutions'"
                          key="solutions"
                          :data="rootStore.ui.modal.menu.solutions"
                          class="z-2"
                        />
                        <!-- end SOLUTIONS -->

                        <!-- MIRAGE WORLD -->
                        <menu-mirage-world
                          v-else-if="navigation === 'mirage-world'"
                          key="mirage-world"
                          :data="rootStore.ui.modal.menu.mirageWorld"
                          class="z-2"
                        />
                        <!-- end MIRAGE WORLD -->

                        <!-- PROFESSIONAL -->
                        <menu-professional
                          v-else-if="navigation === 'professional'"
                          key="professional"
                          :data="rootStore.ui.modal.menu.professional"
                          class="z-2"
                        />
                        <!-- end PROFESSIONAL -->

                        <!-- MENU -->
                        <menu-nav
                          v-else-if="navigation === 'nav'"
                          key="nav"
                          @products="$emit('navigation', 'products')"
                          @solutions="$emit('navigation', 'solutions')"
                          @customizers="$emit('navigation', 'customizers')"
                          @professional="$emit('navigation', 'professional')"
                          @mirage_world="$emit('navigation', 'mirage-world')"
                          @locale="$emit('navigation', 'locale')"
                          class="z-1"
                        />
                        <!-- end MENU -->
                      </transition>
                    </div>
                  </div>
                </div>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import { useRootStore } from "@/stores/root";

import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
  DialogTitle,
} from "@headlessui/vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  navigation: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "nav",
          "locale",
          "products",
          "solutions",
          "customizers",
          "mirage-world",
          "professional",
        ].indexOf(value) !== -1
      );
    },
    default: "nav",
  },
});

const emit = defineEmits(["close", "navigation"]);
const route = useRoute();
const rootStore = useRootStore();

watch(
  () => route.fullPath,
  (newVal, oldVal) => {
    emitClose();
  }
);

function emitClose() {
  emit("close");
}

onMounted(() => {
  window.addEventListener("reload-same-page", emitClose);
});

onBeforeUnmount(() => {
  window.removeEventListener("reload-same-page", emitClose);
});
</script>
