<template>
  <btn
    component-name="btn-pill"
    :title="$attrs.title"
    :action="$attrs.action ? $attrs.action : undefined"
    :disabled="$attrs.disabled ? $attrs.disabled : undefined"
    :pressed="$attrs.pressed ? $attrs.pressed : undefined"
    :data-skin="filled === true ? 'black' : undefined"
    class="flex justify-between items-center gap-md rounded-full transition-colors duration-300 ease-in-out truncate max-w-min"
    :class="{
      'disabled:opacity-50 opacity-50': $attrs.disabled,
      'py-md text-button/none px-xl': !$attrs.size || $attrs.size === 'regular',
      'py-lg text-button-big/none px-xl': $attrs.size === 'big',
      'py-sm text-button-small/none px-lg': $attrs.size === 'small',
      'bg-skin-inverse text-skin-inverse': filled,
      'border border-skin-base text-skin-base bg-transparent hover:text-skin-inverse hover:bg-skin-inverse':
        !filled,
      '!text-skin-inverse !bg-skin-inverse': !filled && $attrs.pressed,
    }"
    @mouseenter="$device.isDesktop ? tpl_hoverIcon.animate() : () => {}"
  >
    <div
      class="grow truncate select-none max-w-full subpixel-antialiased"
      v-html="$attrs.title"
    ></div>

    <hover-icon
      ref="tpl_hoverIcon"
      :icon="icon"
      :size="$attrs.size === 'big' ? 'big' : 'regular'"
      class="min-w-min"
    />
  </btn>
</template>

<script setup>
const props = defineProps({
  filled: {
    type: Boolean,
    required: false,
    default: false,
  },
});
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
const attrs = useAttrs();
const icon = computed(() => {
  return attrs.icon || "arrow-long-right";
});
</script>
