import "swiper/element/css/navigation";
import "swiper/element/css/pagination";
import "swiper/element/css/effect-fade";
import "swiper/element/css/autoplay";
import "swiper/element/css/free-mode";
import "swiper/element/css/controller";
import "swiper/element/css/parallax";
/* 
  swiper/element/css/a11y - styles required for A11y module
  swiper/element/css/autoplay - styles required for Autoplay module
  swiper/element/css/controller - styles required for Controller module
  swiper/element/css/effect-cards - styles required for Cards Effect module
  swiper/element/css/effect-coverflow - styles required for Coverflow Effect module
  swiper/element/css/effect-creative - styles required for Creative Effect module
  swiper/element/css/effect-cube - styles required for Cube Effect module
  swiper/element/css/effect-fade - styles required for Fade Effect module
  swiper/element/css/effect-flip - styles required for Flip Effect module
  swiper/element/css/free-mode - styles required for Free Mode module
  swiper/element/css/grid - styles required for Grid module
  swiper/element/css/hash-navigation - styles required for Hash Navigation module
  swiper/element/css/history - styles required for History module
  swiper/element/css/keyboard - styles required for Keyboard module
  swiper/element/css/manipulation - styles required for Manipulation module
  swiper/element/css/mousewheel - styles required for Mousewheel module
  swiper/element/css/navigation - styles required for Navigation module
  swiper/element/css/pagination - styles required for Pagination module
  swiper/element/css/parallax - styles required for Parallax module
  swiper/element/css/scrollbar - styles required for Scrollbar module
  swiper/element/css/thumbs - styles required for Thumbs module
  swiper/element/css/virtual - styles required for Virtual module
  swiper/element/css/zoom - styles required for Zoom module 
*/

// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// import { EffectFade, Autoplay, FreeMode, Controller } from "swiper";
import {
  EffectFade,
  Autoplay,
  FreeMode,
  Controller,
  Parallax,
} from "swiper/modules";

export default defineNuxtPlugin((NuxtApp) => {
  // register Swiper custom elements
  register();

  const modules = {
    EffectFade,
    Autoplay,
    FreeMode,
    Controller,
    Parallax,
  };

  Object.assign(window, modules);

  return {
    provide: modules,
  };
});
