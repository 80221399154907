import validate from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45404_45global from "/var/www/html/mirage/frontend-2/middleware/01.redirect-404.global.js";
import router_45global from "/var/www/html/mirage/frontend-2/middleware/router.global.js";
import manifest_45route_45rule from "/var/www/html/mirage/frontend-2/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45404_45global,
  router_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/var/www/html/mirage/frontend-2/middleware/auth.js")
}