<template>
  <block component-name="block-text-heading">
    <txt-heading
      size="big"
      :overline="$attrs.data.body.overline || undefined"
      :title="$attrs.data.body.title || undefined"
      :button="$attrs.data.body.button || undefined"
      :force-divider="$attrs.config?.forceDivider === false ? false : true"
      :atelier="$attrs.data.body.atelier || undefined"
      :resource="$attrs.data.body.resource || undefined"
      :text="$attrs.data.body.text || undefined"
      class="w-full"
    />
  </block>
</template>
