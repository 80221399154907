import parser from "accept-language-parser";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const nuxtApp = useNuxtApp();

  if (nuxtApp.$locale) {
    return;
  }

  // XX / XX ---> /?redirect=architects-and-designers

  let mrgCountry = useCookie("mrg_country", {
    maxAge: 60 * 60 * 24 * 365,
  });

  let mrgMarket = useCookie("mrg_market", {
    maxAge: 60 * 60 * 24 * 365,
  });

  let mrgLocale = useCookie("mrg_locale", {
    maxAge: 60 * 60 * 24 * 365,
  });

  let country = to.params.country || mrgCountry.value,
    locale = to.params.locale || mrgLocale.value;

  if (to.params.country && to.params.locale) {
    const { data: checkLocale } = await useFetch(
      `/api/check-locale?country=${to.params.country}&lang=${to.params.locale}`
    );
    if (!checkLocale.value.available) {
      if (mrgCountry.value && mrgLocale.value) {
        return navigateTo(
          `/${mrgCountry.value}/${mrgLocale.value}${
            to.query.redirect ? `/${to.query.redirect}` : ""
          }`,
          {
            redirectCode: 301,
          }
        );
      } else {
        // clear cookies
        mrgCountry.value = null;
        mrgMarket.value = null;
        mrgLocale.value = null;
        return navigateTo(`/`, {
          redirectCode: 301,
        });
      }
    }
  }

  // check if valid combination
  if (country) {
    const { data: validatedMarket } = await useFetch(
      `/api/validate-market-by-country?country=${country}`
    );
    mrgMarket.value = validatedMarket.value;
  } else {
    const geo = await getCountry();
    country = geo.country;
  }

  if (!isValidLang(to.params.locale)) {
    locale = await getLocale(country);
  }

  nuxtApp.provide("country", country);
  nuxtApp.provide("locale", locale);
  nuxtApp.provide("market", mrgMarket.value);

  if (!isValidLang(to.params.locale) || !to.params.country) {
    if (`/${country}/${locale}` !== to.fullPath) {
      const query = Object.keys(to.query)
        .filter((key) => key !== "redirect")
        .map((key) => `${key}=${to.query[key]}`)
        .join("&");
      return navigateTo(
        `/${country}/${locale}${
          to.query.redirect
            ? `/${to.query.redirect}${query ? `?${query}` : ""}`
            : ""
        }`,
        {
          redirectCode: 301,
        }
      );
    }
  }

  async function getLocale() {
    let detectedLanguage = "en";
    if (mrgLocale.value) {
      detectedLanguage = mrgLocale.value;
    } else {
      const acceptLanguage = useRequestHeaders(["accept-language"]);
      const detectedLanguages = parseLanguages(
        acceptLanguage["accept-language"]
      );
      for (let i = 0; i < detectedLanguages.length; i++) {
        if (isValidLang(detectedLanguages[i].code)) {
          detectedLanguage = detectedLanguages[i].code;
          break;
        }
      }
    }

    const { data: checkLocale } = await useFetch(
      `/api/check-locale?country=${country}&lang=${detectedLanguage}`
    );

    if (!checkLocale.value.valid) {
      detectedLanguage = checkLocale.value.available[0];
    }

    mrgLocale.value = detectedLanguage;
    return detectedLanguage;
  }

  async function getCountry() {
    let country = mrgCountry.value;
    let market;
    let iso;
    if (!country) {
      const { data: geo } = await useFetch("/api/country");
      market = geo.value.market;
      country = geo.value.country;
      iso = geo.value.iso;
    }
    mrgCountry.value = country;
    mrgMarket.value = market;
    return { market, country, iso };
  }

  function isValidLang(locale) {
    return /^it$|^en$|^fr$|^de$|^es$|^zh$/.test(locale);
  }

  function parseLanguages(languageHeader) {
    return parser.parse(languageHeader);
  }
});
