<template>
  <article
    component-name="menu-products"
    data-lenis-prevent
    class="overflow-y-auto overscroll-y-contain grid grid-cols-12 gap-x-ui gap-y-2xl bg-skin-base h-full max-h-full [&>*]:max-h-full px-edge laptop:pr-0"
  >
    <section
      class="pt-xl laptop:pt-0 w-full col-span-full laptop:col-start-1 laptop:col-end-6"
    >
      <header
        class="relative top-0 left-0 flex flex-col items-start w-full laptop:sticky gap-3xl laptop:py-2xl laptop:pt-edge"
      >
        <div class="flex flex-col w-full laptop:w-11/12">
          <divider />
          <wrapper-reveal class="w-full" reveal="fade-from-0">
            <btn-solid
              size="huge"
              :title="$t.labels.attr_pa_collezioni"
              :action="{ to: '/products/collections' }"
              :break-all="true"
              :force-mini-px="true"
              icon-right="arrow-long-right"
              class="w-full max-w-full"
            />
          </wrapper-reveal>
          <divider />
          <wrapper-reveal class="w-full" reveal="fade-from-0">
            <btn-solid
              size="huge"
              :title="$t.labels.find_product"
              :action="{ to: '/products/find-product' }"
              :break-all="true"
              :force-mini-px="true"
              icon-right="arrow-long-right"
              class="w-full max-w-full"
            />
          </wrapper-reveal>
          <divider />
        </div>

        <section class="justify-start hidden w-full laptop:flex gap-3xl">
          <div class="flex flex-col gap-2xl">
            <wrapper-reveal reveal="fade-from-right">
              <h2 class="text-heading-5/none" v-html="$t.labels.effect"></h2>
            </wrapper-reveal>
            <ul class="flex flex-col list-none gap-md">
              <wrapper-reveal
                tag="li"
                v-for="item in data.effect"
                reveal="slide-from-right"
              >
                <btn-nav
                  size="big"
                  :title="toFirstUppercase(item.title)"
                  :action="item.action"
                />
              </wrapper-reveal>
            </ul>
          </div>

          <div class="flex flex-col gap-2xl">
            <wrapper-reveal reveal="fade-from-right">
              <h2
                class="text-heading-5/none"
                v-html="$t.labels.attr_pa_colore"
              ></h2>
            </wrapper-reveal>

            <wrapper-reveal class="w-full" reveal="slide-from-right">
              <div class="grid w-full grid-cols-6 gap-xs">
                <wrapper-reveal
                  v-for="item in data.color"
                  class="w-full col-span-1"
                  reveal="slide-from-down reveal-strong"
                >
                  <btn-color-swatch
                    :title="item.title"
                    :action="item.action"
                    :asset="item.asset"
                  />
                </wrapper-reveal>
              </div>
            </wrapper-reveal>
          </div>
        </section>
      </header>
    </section>

    <section
      class="flex flex-col h-full max-h-full col-span-full laptop:col-start-6 laptop:col-end-13 gap-xs laptop:gap-2xs pb-[calc(100lvh-100svh)] laptop:pb-0 mb-2xs laptop:mb-0"
    >
      <card-launcher
        v-for="item in data.style_and_mood"
        class="w-full laptop:[&_[component-name=media]]:max-h-[80svh]"
        :overline="item.overline"
        :title="item.title"
        :title-details="item.totalProducts || undefined"
        :text="item.text || undefined"
        :action="item.action"
        :asset="{
          ...item.asset,
          sizes: {
            base: 600,
            phablet: 1020,
            tablet: 1272,
            laptop: 756,
            desktop: 960,
            extdesktop: 1280,
          },
        }"
        :useTracking="{
          event: 'click_sottovoce_menu',
          voce_menu: item.title,
        }"
      />
    </section>
  </article>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

// first letter of the string uppercase  all other lowercase
const toFirstUppercase = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
</script>
