const _0 = "0"
const _1 = 1
const _2 = 2
const _3 = 3
const _4 = 4
const _5 = 5
const _6 = 6
const _7 = 7
const _8 = 8
const _9 = 9
const _10 = "10"
const _20 = "20"
const _30 = "30"
const _40 = "40"
const _50 = "50"
const _auto = "auto"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "20": _20, "30": _30, "40": _40, "50": _50, "auto": _auto,  }
export { config as default, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _20, _30, _40, _50, _auto }