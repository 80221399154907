<template>
  <article
    component-name="menu-locale"
    data-lenis-prevent
    class="overflow-y-auto overscroll-y-contain laptop:grid grid-cols-12 gap-ui laptop:gap-x-3xl bg-skin-base h-full max-h-full [&>*]:max-h-full px-edge"
  >
    <section
      class="w-full col-span-full laptop:col-start-1 laptop:col-end-7 laptop:pr-edge"
    >
      <header class="relative top-0 left-0 laptop:sticky py-4xl">
        <txt-heading
          size="big"
          :title="$t.labels.select_market_language"
          :text="$t.texts.select_market_language"
          class="w-full"
        />
      </header>
    </section>

    <section
      class="flex flex-col items-stretch w-full col-span-full laptop:col-start-7 laptop:col-end-13 pb-2xl laptop:py-4xl"
    >
      <div class="flex flex-col w-full gap-2xl">
        <collapse-group :always-open="false" class="w-full">
          <collapse
            v-for="(market, marketIndex) in data.items"
            :title="market.title"
            class="w-full"
          >
            <template #header="collapseProps">
              <btn-solid
                size="huge"
                button-tag="summary"
                :title="collapseProps.title"
                :icon-right="
                  collapseProps.isOpen
                    ? collapseProps.icons.opened
                    : collapseProps.icons.closed
                "
                class="w-full"
              />
            </template>

            <ul
              class="flex flex-col items-stretch w-full max-w-full list-none gap-xl tablet:pl-md pb-lg"
            >
              <li
                v-for="(country, countryIndex) in market.countries"
                class="flex items-center justify-between w-full max-w-full gap-md"
              >
                <div
                  class="flex items-center justify-start grow-0 gap-xs tablet:gap-md"
                >
                  <media
                    v-if="country.asset"
                    tag="div"
                    class="w-7 shrink-0 phablet:w-8 tablet:w-9"
                    v-bind="country.asset"
                    :sizes="{
                      base: 42,
                      phablet: 64,
                      tablet: 72,
                    }"
                    :use-bg="false"
                  />

                  <div
                    class="text-ellipsis line-clamp-1 text-body/none phablet:heading-6/none"
                  >
                    {{ country.title }}
                  </div>
                </div>

                <wrapper-reveal
                  v-if="country.items?.length"
                  class="flex items-baseline justify-end grow gap-sm tablet:gap-md min-w-min"
                  reveal="fade-from-left"
                >
                  <btn
                    v-for="countryLanguage in country.items"
                    :title="countryLanguage.title"
                    :action="countryLanguage.action"
                    class="uppercase text-button-big"
                    @click.stop.prevent="changeLocation(countryLanguage.action)"
                    >{{ countryLanguage.title }}</btn
                  >
                </wrapper-reveal>
              </li>
            </ul>
          </collapse>
        </collapse-group>
      </div>
    </section>
  </article>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

let mrgCountry = useCookie("mrg_country", {
  maxAge: 60 * 60 * 24 * 365,
});

let mrgLocale = useCookie("mrg_locale", {
  maxAge: 60 * 60 * 24 * 365,
});

function changeLocation(action) {
  const [country, language] = action.href.split("/").filter((item) => item);
  mrgCountry.value = country;
  mrgLocale.value = language;
  window.location.href = action.href;
}
</script>
