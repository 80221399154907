<template>
  <div component-name="btn-list" class="flex flex-col gap-xl">
    <div v-if="asset" class="w-full h-20">
      <media
        v-bind="asset"
        :use-bg="false"
        :sizes="{ base: 150 }"
        fit="contain"
        position="left"
        data-skin="black"
        class="w-auto h-full max-w-full max-h-full"
        reveal="unveil-from-right"
      />
    </div>

    <wrapper-split-text v-if="title" reveal="chars" class="w-full">
      <div v-html="title" class="w-full text-heading-5"></div>
    </wrapper-split-text>

    <menu
      v-if="items?.length"
      class="flex flex-col items-start list-none border-current divide-y divide-current text-skin-base max-w-full"
      v-surfer="{
        setup: {
          destroyOn: 'visible',
        },
        observers: {
          init: true,
          visible: { event: true },
          offscreenTop: { event: true },
          offscreenBottom: { event: true },
        },
      }"
      @surfer-visible="surferState = 'visible'"
      @surfer-offscreen-top="surferState = 'offscreen-top'"
      @surfer-offscreen-bottom="surferState = 'offscreen-bottom'"
    >
      <li
        v-for="(item, index) in items"
        class="flex flex-col items-stretch w-full max-w-full unveil-from-left delay-[--unveil-delay]"
        :class="{
          'surfer-offscreen-top': surferState === 'offscreen-top',
          'surfer-visible': surferState === 'visible',
        }"
        :style="`--unveil-delay: ${
          surferState === 'visible' ? index * 70 : 0
        }ms`"
      >
        <btn-solid
          :size="$attrs.size || 'regular'"
          :title="item.title"
          :text="item.text || undefined"
          :action="item.action ? item.action : undefined"
          :disabled="item.disabled ? item.disabled : undefined"
          :pressed="item.pressed ? item.pressed : undefined"
          :icon-left="item.icon || undefined"
          class="w-full max-w-full"
          :class="{
            'border-t border-skin-base': index === 0,
            'border-b border-skin-base': index === items.length - 1,
          }"
        />
      </li>
    </menu>
  </div>
</template>

<script setup>
const props = defineProps({
  asset: {
    type: Object,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  items: {
    type: Array,
    required: false,
  },
});

const surferState = ref(false);
</script>
