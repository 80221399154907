export default defineNuxtPlugin((nuxtApp) => {
  const clone = (obj) => {
    return obj ? JSON.parse(JSON.stringify(obj)) : {};
  };

  const useThrottle = (func, wait, options) => {
    let context, args, result;
    let timeout = null;
    let previous = 0;
    if (!options) options = {};
    const later = function () {
      previous = options.leading === false ? 0 : Date.now();
      timeout = null;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    };
    const clear = function () {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
    function throttle() {
      const now = Date.now();
      if (!previous && options.leading === false) previous = now;
      const remaining = wait - (now - previous);
      context = this;
      args = arguments;
      if (remaining <= 0 || remaining > wait) {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        previous = now;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      } else if (!timeout && options.trailing !== false) {
        timeout = setTimeout(later, remaining);
      }
      return result;
    }

    return {
      throttle,
      clear,
    };
  };

  const isEmpty = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  const rootBoundsFixed = (rootMargin) => {
    //  if rootMargin is set to 0px 0px 0px 0px
    if (!rootMargin) {
      return {
        top: 0,
        left: 0,
        x: 0,
        y: 0,
        bottom: window.innerHeight,
        right: window.innerWidth,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    } else {
      const rootMarginArray = rootMargin
        .split(" ")
        .map((margin) => parseInt(margin.replace("px", "")));

      const top = rootMarginArray[0] != 0 ? (rootMarginArray[0] *= -1) : 0;
      const right = rootMarginArray[1] != 0 ? (rootMarginArray[1] *= -1) : 0;
      const bottom = rootMarginArray[2] != 0 ? (rootMarginArray[2] *= -1) : 0;
      const left = rootMarginArray[3] != 0 ? (rootMarginArray[3] *= -1) : 0;

      return {
        top: top,
        left: left,
        x: left,
        y: top,
        bottom: window.innerHeight - bottom,
        right: window.innerWidth - right,
        width: window.innerWidth - left - right,
        height: window.innerHeight - top - bottom,
      };
    }
  };

  const dispose = (el, destroyOn) => {
    if (el.ghostID) {
      document.getElementById(el.ghostID).remove();
      delete el.ghostID;
    }

    if (!el.observer) {
      return;
    }

    // detect if device suppprts the orientationchange event
    const supportsOrientationChange = "onorientationchange" in window;
    window.removeEventListener(
      supportsOrientationChange ? "orientationchange" : "resize",
      el.throttleResizeSurfer
    );
    el.clearThrottleResizeSurfer ? el.clearThrottleResizeSurfer() : null;
    el.throttleResizeSurfer = () => {};
    el.clearThrottleResizeSurfer();
    delete el.throttleResizeSurfer;
    delete el.clearThrottleResizeSurfer;

    window.removeEventListener("scroll", el.throttle_fullyCoversViewport);
    if (el.clearThrottle_fullyCoversViewport) {
      el.clearThrottle_fullyCoversViewport();
      delete el.clearThrottle_fullyCoversViewport;
    }
    delete el.throttle_fullyCoversViewport;

    el.observer.disconnect();
    el.observer = null;
    delete el.observer;

    // remove all classes starting with surfer- from an HTML element className
    if (typeof destroyOn == "object" && destroyOn.clearClasses) {
      el.setAttribute(
        "class",
        el
          .getAttribute("class")
          .replace(new RegExp("(^|\\s)surfer-\\S+", "g"), "")
      );
      //remove all classes from el.surferClasses
      if (el.surferClasses) {
        el.surferClasses.forEach((className) => {
          el.classList.remove(className);
        });
      }
    }
  };

  const intersectionObserver = (el, binding, vnode, intersectionEl) => {
    const params = binding.value;

    let rootMargin = params?.setup?.rootMargin || "0px 0px 0px 0px";
    let threshold = params?.setup?.threshold || [0, 1];
    let destroyOn = params?.setup?.destroyOn;
    let destroyOnEvent;
    if (destroyOn) {
      destroyOnEvent = `surfer-${
        typeof destroyOn == "object" ? destroyOn.observer || false : destroyOn
      }`;
    }

    let debug = params?.setup?.debug || false;

    const state = {};

    let boundingClientRect;
    let intersectionRatio;
    let intersectionRect;
    let isIntersecting;
    let rootBounds;
    let target;
    let time;
    let viewportState;

    let ready = false;

    const addConfig = (type, obj) => {
      if (typeof obj == "string") {
        obj = { class: obj };
      }
      return obj ? Object.assign({ type: type }, obj) : false;
    };

    const observers = params?.observers ? params?.observers : params;

    const configs = {
      init: addConfig("surfer-init", observers?.init),
      enterFromTop: addConfig("surfer-enter-from-top", observers?.enterFromTop),
      enterFromBottom: addConfig(
        "surfer-enter-from-bottom",
        observers?.enterFromBottom
      ),
      offscreen: addConfig("surfer-offscreen", observers?.offscreen),
      offscreenTop: addConfig("surfer-offscreen-top", observers?.offscreenTop),
      offscreenBottom: addConfig(
        "surfer-offscreen-bottom",
        observers?.offscreenBottom
      ),
      visible: addConfig("surfer-visible", observers?.visible),
      visibleFill: addConfig("surfer-visible-fill", observers?.visibleFill),
      visiblePartially: addConfig(
        "surfer-visible-partially",
        observers?.visiblePartially
      ),
      visibleFully: addConfig("surfer-visible-fully", observers?.visibleFully),
    };

    const addObserver = (params) => {
      const { condition, observer, data } = params;

      if (condition /*  && !state[observer.type] */) {
        state[observer.type] = true;

        addClass(observer.type, observer);
        if (observer.event && !observer._emitted) {
          emitEvent(observer.type, data);
          observer._emitted = true;
          if (observer.event == "once") {
            observer.event = false;
          }
        }
      } else if (!condition) {
        delete state[observer.type];

        removeClass(observer.type, observer);
        if (observer?.event) {
          observer._emitted = false;
        }
      }

      if (condition && observer.type == destroyOnEvent) {
        dispose(el, destroyOn);
      }
    };

    let callback = (entries) => {
      const ENTRY = entries[0];

      boundingClientRect = ENTRY.boundingClientRect;
      intersectionRatio = ENTRY.intersectionRatio;
      intersectionRect = ENTRY.intersectionRect;
      isIntersecting = ENTRY.isIntersecting;
      rootBounds = ENTRY.rootBounds || rootBoundsFixed(rootMargin);
      target = ENTRY.target;
      time = ENTRY.time;

      const records = {
        isInViewport: isInViewport(),
        isFullyInViewport: isFullyInViewport(),
        isAboveViewport: isAboveViewport(),
        isBelowViewport: isBelowViewport(),
        isFullyBelowViewport: isFullyBelowViewport(),
        isFullyAboveViewport: isFullyAboveViewport(),
      };

      const OLD_VIEWPORT_STATE = Object.assign({}, clone(viewportState));

      viewportState = records;

      const DATA = Object.assign(clone(records), {
        boundingClientRect: boundingClientRect,
        intersectionRatio: intersectionRatio,
        intersectionRect: intersectionRect,
        isIntersecting: isIntersecting,
        rootBounds: rootBounds,
        target: target,
        time: time,
      });

      if (configs.init) {
        if (!ready) {
          addClass("surfer-init", configs.init);
          emitEvent(configs.init.type, DATA);
          ready = true;
        }
      }

      const executeObservers = [];

      if (configs.enterFromTop) {
        executeObservers.push({
          condition:
            (DATA.isInViewport &&
              DATA.isAboveViewport &&
              OLD_VIEWPORT_STATE.isBelowViewport) ||
            (DATA.isInViewport && OLD_VIEWPORT_STATE.isAboveViewport),
          observer: configs.enterFromTop,
          data: DATA,
        });
      }

      if (configs.enterFromBottom) {
        executeObservers.push({
          condition:
            DATA.isInViewport && OLD_VIEWPORT_STATE.isFullyBelowViewport,
          observer: configs.enterFromBottom,
          data: DATA,
        });
      }

      if (configs.offscreen) {
        executeObservers.push({
          condition: !DATA.isInViewport,
          observer: configs.offscreen,
          data: DATA,
        });
      }

      if (configs.offscreenTop) {
        executeObservers.push({
          condition: !DATA.isInViewport && DATA.isAboveViewport,
          observer: configs.offscreenTop,
          data: DATA,
        });
      }

      if (configs.offscreenBottom) {
        executeObservers.push({
          condition: !DATA.isInViewport && DATA.isBelowViewport,
          observer: configs.offscreenBottom,
          data: DATA,
        });
      }

      if (configs.visibleFully) {
        executeObservers.push({
          condition: DATA.isFullyInViewport,
          observer: configs.visibleFully,
          data: DATA,
        });
      }

      if (configs.visiblePartially) {
        executeObservers.push({
          condition: DATA.isInViewport && !OLD_VIEWPORT_STATE.isInViewport,
          observer: configs.visiblePartially,
          data: DATA,
        });
      }

      if (configs.visible) {
        executeObservers.push({
          condition: DATA.isInViewport,
          observer: configs.visible,
          data: DATA,
        });
      }

      // sort observers by condition: false first
      executeObservers.sort((a, b) => {
        return a.condition === b.condition ? 0 : a.condition ? 1 : -1;
      });

      for (let i = 0; i < executeObservers.length; i++) {
        addObserver(executeObservers[i]);
      }
    };

    let options = {
      /*
              The DOM element
            */
      root: null,
      /*
                Margin around the root.
                Can have values similar to the CSS margin property, e.g. "10px 20px 30px 40px" (top, right, bottom, left).
                If the root element is specified, the values can be percentages.
                This set of values serves to grow or shrink each side of the root element's bounding box before computing intersections.
                Defaults to all zeros.
            */
      rootMargin: rootMargin,
      /*
                Either a single number or an array of numbers which indicate at what percentage of the target's visibility the observer's callback should be executed.
                If you only want to detect when visibility passes the 50% mark, you can use a value of 0.5.
                If you want the callback run every time visibility passes another 25%, you would specify the array [0, 0.25, 0.5, 0.75, 1].
                The default is 0 (meaning as soon as even one pixel is visible, the callback will be run).
                A value of 1.0 means that the threshold isn't considered passed until every pixel is visible.
            */
      threshold: threshold,
    };

    const fullyCoversViewport = () => {
      const rect = el.getBoundingClientRect();
      addObserver({
        condition: rect.top <= 0 && rect.bottom >= window.innerHeight,
        observer: configs.visibleFill,
        data: rect,
      });
    };

    el.observer = new IntersectionObserver(callback, options);
    el.observer.observe(intersectionEl ? intersectionEl : el);

    if (configs.visibleFill && !el.throttle_fullyCoversViewport) {
      const { throttle, clear } = useThrottle(fullyCoversViewport, 100, {
        leading: false,
        trailing: true,
      });
      el.clearThrottle_fullyCoversViewport = clear;
      el.throttle_fullyCoversViewport = throttle;
      window.addEventListener("scroll", el.throttle_fullyCoversViewport);
      el.throttle_fullyCoversViewport();
    }

    function removeClass(type, options) {
      if (typeof options == "string") {
        type = type.concat(` ${options}`);
      } else if (typeof options == "object" && options.class) {
        type = type.concat(` ${options.class}`);
      }
      const classes = type.trim().split(" ");
      for (let i = 0; i < classes.length; i++) {
        // remove class from array of classes if there
        if (el.surferClasses && el.surferClasses.includes(classes[i])) {
          el.surferClasses.splice(el.surferClasses.indexOf(classes[i]), 1);
        }
        el.classList.remove(classes[i]);
      }
    }

    function addClass(type, options) {
      if (typeof options == "string") {
        type = type.concat(` ${options}`);
      } else if (typeof options == "object" && options.class) {
        type = type.concat(` ${options.class}`);
      }
      const classes = type.trim().split(" ");
      for (let i = 0; i < classes.length; i++) {
        if (!el.surferClasses) {
          el.surferClasses = [];
        }
        // add class to array of classes if not already there
        if (
          typeof classes[i] == "string" &&
          !el.surferClasses.includes(classes[i])
        ) {
          el.surferClasses.push(classes[i]);
        }
        el.classList.add(classes[i]);
      }
    }

    function emitEvent(event, entry) {
      if (vnode.componentInstance) {
        vnode.componentInstance.$emit(event, entry);
      } else {
        el.dispatchEvent(
          new CustomEvent(event, {
            bubbles: false,
            detail: entry,
          })
        );
      }
    }

    /*
     *  isInViewport: true if any part of the element is visible, false if not.
     */
    const isInViewport = () => isIntersecting === true;

    /*
     *  isFullyInViewport: true if the entire element is visible inside the root element
     */
    const isFullyInViewport = () =>
      isIntersecting === true && intersectionRatio == 1;

    /*
     *  isAboveViewport: true if any part of the element is above the root
     */
    const isAboveViewport = () => boundingClientRect.top < 0;

    /*
     *  isBelowViewport: true if any part of the element is below the root.
     */
    const isBelowViewport = () =>
      rootBounds && boundingClientRect.bottom > rootBounds.bottom;

    /*
     *  isFullyBelowViewport: true if the whole element is below the root.
     */
    const isFullyBelowViewport = () =>
      !isIntersecting &&
      rootBounds &&
      boundingClientRect.bottom > rootBounds.bottom;
    /*
     *  isFullyAboveViewport: true if the whole element is below the root.
     */
    const isFullyAboveViewport = () =>
      !isIntersecting && boundingClientRect.top < 0;
  };

  const getScrollParent = (node) => {
    if (node == null) {
      return null;
    }
    if (node.scrollHeight > node.clientHeight) {
      return node;
    } else {
      return getScrollParent(node.parentNode);
    }
  };

  const getEl = (el) => {
    // get is valid HTML element
    const _el = typeof el == "string" ? document.querySelector(el) : el;
    const isValidElement = (object) => {
      return object instanceof Element || object instanceof HTMLDocument;
    };
    if (!isValidElement(_el)) {
      console.error("Invalid HTML element");
      return;
    }
    return _el;
  };

  const init = (el, binding, vnode, prevVnode) => {
    if (el.observer) {
      dispose(el);
    }

    if (
      !binding.value ||
      isEmpty(binding.value) ||
      (binding.value.observers && isEmpty(binding.value.observers))
    ) {
      return;
    }

    const createGhostDiv = () => {
      const startEl = binding.value.setup?.triggerStart
        ? getEl(binding.value.setup.triggerStart)
        : el;

      const endEl = binding.value.setup?.triggerEnd
        ? getEl(binding.value.setup.triggerEnd)
        : el;

      let rectHeight = el.getBoundingClientRect().height;
      if (
        binding.value.setup?.triggerStart &&
        binding.value.setup?.triggerEnd
      ) {
        rectHeight =
          endEl.offsetTop +
          endEl.getBoundingClientRect().height -
          startEl.offsetTop;
      } else if (
        binding.value.setup?.triggerStart &&
        !binding.value.setup?.triggerEnd
      ) {
        rectHeight =
          el.offsetTop + el.getBoundingClientRect().height - startEl.offsetTop;
      } else if (
        !binding.value.setup?.triggerStart &&
        binding.value.setup?.triggerEnd
      ) {
        rectHeight =
          endEl.offsetTop + endEl.getBoundingClientRect().height - el.offsetTop;
      } else {
        rectHeight = el.getBoundingClientRect().height;
      }

      const ghostDiv = document.createElement("div");
      ghostDiv.style.position = "absolute";
      ghostDiv.style.top = "0";
      ghostDiv.style.left = "0";
      ghostDiv.style.width = "100vw";
      ghostDiv.style.height = `${rectHeight}px`;
      ghostDiv.style.zIndex = "9999"; // "-1";
      // ghostDiv.style.visibility = "hidden";
      ghostDiv.style.pointerEvents = "none";
      ghostDiv.style.border = "2px dashed limegreen";
      // add class "ghost" to ghost div
      ghostDiv.classList.add("ghost");
      // make id unique
      ghostDiv.id = `ghost-${Math.floor(Math.random() * 1000000)}`;
      // append to body
      const parent = getScrollParent(el);
      parent.appendChild(ghostDiv);
      // translate at "el" position
      ghostDiv.style.transform = `translate(0px, ${startEl.offsetTop}px)`;
      return ghostDiv;
    };

    let intersectionEl = el;

    if (binding.value.setup?.trigger) {
      intersectionEl = getEl(binding.value.setup.trigger);
    } else if (
      binding.value.setup?.triggerStart ||
      binding.value.setup?.triggerEnd
    ) {
      intersectionEl = createGhostDiv();
      el.ghostID = intersectionEl.id;
    }

    intersectionObserver(el, binding, vnode, intersectionEl);

    const { throttle, clear } = useThrottle(
      () => {
        if (
          window.innerHeight == el.innerHeight &&
          window.innerWidth == el.innerWidth
        ) {
          return;
        }
        init(el, binding, vnode, prevVnode);
      },
      500,
      { leading: false, trailing: true }
    );

    el.clearThrottleResizeSurfer = clear;
    el.throttleResizeSurfer = throttle;

    const supportsOrientationChange = "onorientationchange" in window;
    window.addEventListener(
      supportsOrientationChange ? "orientationchange" : "resize",
      el.throttleResizeSurfer
    );

    el.innerHeight = window.innerHeight;
    el.innerWidth = window.innerWidth;
  };

  nuxtApp.vueApp.directive("surfer", {
    // called before bound element's attributes or event listeners are applied
    created(el, binding, vnode, prevVnode) {
      // see below for details on arguments
    },
    // called right before the element is inserted into the DOM.
    beforeMount(el, binding, vnode, prevVnode) {},
    // called when the bound element's parent component and all its children are mounted.
    mounted(el, binding, vnode, prevVnode) {
      if (!process.client) {
        return;
      }
      dispose(el);
      if (!binding.value) {
        return;
      }
      setTimeout(() => {
        init(el, binding, vnode, prevVnode);
      }, binding.value.setup?.initTimeout || 30);

      el.onSurferRefresh = () => {
        dispose(el);
        init(el, binding, vnode, prevVnode);
      };
      window.addEventListener("surfer-refresh", el.onSurferRefresh);

      if (!window.Surfer) {
        window.Surfer = {
          refresh: () => {
            window.dispatchEvent(new Event("surfer-refresh"));
          },
        };
      }
    },
    // called before the parent component is updated
    beforeUpdate(el, binding, vnode, prevVnode) {},
    // called after the parent component and all of its children have updated
    updated(el, binding, vnode, prevVnode) {
      //   if (!binding.value && !binding.oldValue) {
      //     return;
      //   }
      //   if (JSON.stringify(binding.value) == JSON.stringify(binding.oldValue)) {
      //     return;
      //   }
      //   dispose(el);
      //   init(el, binding, vnode, prevVnode);
    },
    // called before the parent component is unmounted
    beforeUnmount(el, binding, vnode, prevVnode) {
      window.removeEventListener("surfer-refresh", el.onSurferRefresh);
      dispose(el);
    },
    // called when the parent component is unmounted
    unmounted(el, binding, vnode, prevVnode) {},
    getSSRProps(binding, vnode) {
      // you can provide SSR-specific props here
      return {};
    },
  });
});
