import { VueReCaptcha } from "vue-recaptcha-v3";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: "6LeYCAEpAAAAAPhQWp7rXQ8PWxVnyJ4xIvBkUpcw",
    loaderOptions: {
      autoHideBadge: true,
      explicitRenderParameters: {
          badge: "bottomleft",
          size: "invisible",
      },
    },
  });
});
