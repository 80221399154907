export const useGlobals = () => {
  const _storeLocatorEnabled = useState("_storeLocatorEnabled", () => false);
  const _appInert = useState("_appInert", () => false);

  const setStoreLocatorEnabled = (value) => {
    _storeLocatorEnabled.value = value;
  };

  const setAppInert = (value) => {
    _appInert.value = value;
  };

  return {
    setStoreLocatorEnabled,
    setAppInert,
    storeLocatorEnabled: computed(() => _storeLocatorEnabled.value),
    appInert: computed(() => _appInert.value),
  };
};
