<template>
  <client-only>
    <TransitionRoot :show="isOpen" as="template">
      <Dialog
        component-name="modal-copyright-disclaimer"
        data-skin="white"
        class="relative z-50"
        data-lenis-prevent
      >
        <TransitionChild
          enter="transition-opacity duration-300 ease-out"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity duration-200 ease-in"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <!-- The backdrop, rendered as a fixed sibling to the panel container -->
          <div
            class="modal-backdrop"
            aria-hidden="true"
            @click="emit('close')"
          ></div>
        </TransitionChild>

        <!-- Full-screen scrollable container -->
        <div
          class="pointer-events-none overflow-clip fixed inset-0 flex justify-end w-full h-full"
        >
          <!-- Container to center the panel -->
          <div class="flex justify-end items-stretch w-full h-full max-h-full">
            <TransitionChild
              enter="transition-transform duration-300 ease-out delay-200"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transition-transform duration-300 ease-in"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <!-- The actual dialog panel -->
              <component
                :is="$device.isMobile ? 'div' : DialogPanel"
                data-skin="white"
                class="pointer-events-auto flex flex-col justify-between w-[calc(100vw-60px)] max-w-[650px] h-full max-h-full bg-skin-base"
              >
                <div
                  class="grow overflow-y-auto w-full px-edge pb-edge desktop:px-2xl desktop:pb-2xl"
                >
                  <txt-prose
                    class="w-full"
                    :html="rootStore.copyrightDisclaimer"
                  />
                </div>

                <footer
                  data-skin="black"
                  class="grow-0 w-full min-h-min bg-skin-base text-skin-base px-edge py-2xl"
                >
                  <btn-pill
                    size="big"
                    icon-left="success"
                    :title="$t.labels.accept_and_continue"
                    :action="{ ...action, ignoreDisclaimer: true }"
                    @click="handleClick"
                  />
                </footer>
              </component>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </client-only>
</template>

<script setup>
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogPanel,
} from "@headlessui/vue";
import { useRootStore } from "@/stores/root";

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
  action: {
    type: Object,
    required: true,
  },
});

const route = useRoute();
const rootStore = useRootStore();
const emit = defineEmits(["close", "accept"]);

watch(route, (newVal, oldVal) => {
  emit("close");
});

watch(
  () => props.isOpen,
  (value) => {
    if (!value) {
      return;
    }

    if (!rootStore.copyrightDisclaimer) {
      rootStore.loadCopyrightDisclaimer();
    }
  }
);

function handleClick() {
  rootStore.setDownloadsCopyrightDisclaimerCookie("accepted");
  emit("accept");
}
</script>
