<template>
  <component
    component-name="wrapper-reveal"
    :is="tag"
    v-surfer="
      finalReveal
        ? {
            setup: {
              destroyOn: 'visible',
            },
            observers: {
              init: finalReveal,
              visible: true,
              offscreenTop: true,
            },
          }
        : undefined
    "
  >
    <slot />
  </component>
</template>

<script setup>
const props = defineProps({
  tag: {
    type: String,
    required: false,
    default: "div",
  },
  reveal: {
    type: String,
    required: false,
  },
  intensity: {
    type: String,
    required: false,
    validator: function (value) {
      return ["soft", "regular", "strong"].indexOf(value) !== -1;
    },
    default: "regular",
  },
  delay: {
    type: [Number, String],
    required: false,
    validator: function (value) {
      return [0, 50, 100, 150, 200, 250, 300].indexOf(parseInt(value)) !== -1;
    },
    default: 0,
  },
});
const nuxtApp = useNuxtApp();
const { isMobile } = useDevice();

const finalReveal = computed(() => {
  let reveal = props.reveal || null;

  if (reveal) {
    switch (props.intensity) {
      case "soft":
        reveal = `${reveal} reveal-soft`;
        break;

      case "strong":
        reveal = `${reveal} reveal-strong`;
        break;
    }

    switch (parseInt(props.delay)) {
      case 50:
        reveal = `${reveal} [&.surfer-visible]:delay-50`;
        break;

      case 100:
        reveal = `${reveal} [&.surfer-visible]:delay-100`;
        break;

      case 150:
        reveal = `${reveal} [&.surfer-visible]:delay-150`;
        break;

      case 200:
        reveal = `${reveal} [&.surfer-visible]:delay-200`;
        break;

      case 250:
        reveal = `${reveal} [&.surfer-visible]:delay-[250ms]`;
        break;

      case 300:
        reveal = `${reveal} [&.surfer-visible]:delay-300`;
        break;
    }
  }

  return reveal;
});
</script>
