<template>
  <btn
    component-name="btn-icon"
    :data-variant="variant"
    :title="$attrs.title"
    :action="$attrs.action ? $attrs.action : undefined"
    :disabled="$attrs.disabled ? $attrs.disabled : undefined"
    :pressed="$attrs.pressed ? $attrs.pressed : undefined"
    :size="$attrs.size ? $attrs.size : undefined"
    :data-skin="variant === 'naked' ? undefined : 'black'"
    class="flex justify-center items-center leading-none group/btn-icon"
    :class="{
      'w-auto h-auto min-w-min max-w-min min-h-min max-h-min text-skin-base':
        variant === 'naked',
      'w-12 h-12':
        variant !== 'naked' && ($attrs?.size === 'regular' || !$attrs?.size),
      'w-9 h-9': variant !== 'naked' && $attrs?.size === 'small',
      'w-[69px] h-[69px]': variant !== 'naked' && $attrs?.size === 'big',
      'border border-current text-skin-base':
        variant === 'rounded' || variant === 'squared',
      'text-skin-base bg-skin-base': variant === 'squared-filled',
      'text-skin-inverse bg-skin-inverse': variant === 'rounded-filled',
      'rounded-full': variant === 'rounded' || variant === 'rounded-filled',
      'rounded-md': variant === 'squared' || variant === 'squared-filled',
      'opacity-60 !pointer-events-none': $attrs.disabled,
    }"
    @mouseenter="$device.isDesktop ? tpl_hoverIcon.animate() : () => {}"
  >
    <hover-icon ref="tpl_hoverIcon" :icon="icon" :size="iconSize" />
  </btn>
</template>

<script setup>
const props = defineProps({
  variant: {
    type: String,
    required: false,
    validator: function (value) {
      return (
        [
          "naked",
          "rounded",
          "rounded-filled",
          "squared",
          "squared-filled",
          "external-link",
        ].indexOf(value) !== -1
      );
    },
    default: "naked",
  },

  icon: {
    type: String,
    required: true,
  },
});
const attrs = useAttrs();
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
const iconSize = computed(() => {
  let size;

  switch (attrs.size) {
    case "huge":
      size = "huge";
      break;

    case "big":
      size = "big";
      break;

    default:
      size = "regular";
      break;
  }

  return size;
});
</script>
