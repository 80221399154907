import { default as _4049Gcr0ZrcU8Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/404.vue?macro=true";
import { default as indexMoVxlzVBcpMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/index.vue?macro=true";
import { default as indexFv8nlj2s0KMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/password-[type]/index.vue?macro=true";
import { default as indexYRkbs01K0VMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/favourites/index.vue?macro=true";
import { default as indexryNDCvIN71Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue?macro=true";
import { default as indexuXLcImnDL3Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/moodboards/index.vue?macro=true";
import { default as personal_45areaaaa7INZmYSMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area.vue?macro=true";
import { default as index83xmtm5UxEMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile/change-password/index.vue?macro=true";
import { default as index4buNDtAbiwMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile/info/index.vue?macro=true";
import { default as profiletbdS0DqYXiMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile.vue?macro=true";
import { default as indexhKiZvakMvYMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/architects-and-designers/index.vue?macro=true";
import { default as indexCUnaC6cjzDMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/contacts/index.vue?macro=true";
import { default as indexi2EOQMDoOrMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/carousel/index.vue?macro=true";
import { default as indexHW8S2Be6ZsMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/divider/index.vue?macro=true";
import { default as indexIR5KsHN6mgMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/form/index.vue?macro=true";
import { default as indexTOspQXzwpEMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/grid/index.vue?macro=true";
import { default as indexC3Yf4Lqy4lMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/launcher/index.vue?macro=true";
import { default as indexUCLECcYuRFMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/media/index.vue?macro=true";
import { default as indexszTPpRnE1xMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/text/index.vue?macro=true";
import { default as indexpOl1z02s8QMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/toolbar/index.vue?macro=true";
import { default as blocklJSqu6vk8FMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block.vue?macro=true";
import { default as carousel_45pinwm5jWNuC0uMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/carousel-pin.vue?macro=true";
import { default as composerVTkkZ3wtjJMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/composer.vue?macro=true";
import { default as easeLyYYv726pNMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/ease.vue?macro=true";
import { default as geolistVufBhoeYm0Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/geolist.vue?macro=true";
import { default as indexKo5dz8a2nSMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/index.vue?macro=true";
import { default as media_45optionsnn6kEAI3DUMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/media-options.vue?macro=true";
import { default as surfer8YIl00dYcbMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/surfer.vue?macro=true";
import { default as _91id_93vQpUs9lPHKMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/design-stories/[id].vue?macro=true";
import { default as indexkRH1BYys3PMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/faq/index.vue?macro=true";
import { default as indexVEqRrbETt1Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/index.vue?macro=true";
import { default as indexKwz45dVdz2Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue?macro=true";
import { default as index29MUaMceaUMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue?macro=true";
import { default as indexfahS7qNviXMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue?macro=true";
import { default as indexJdRyBpGse7Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue?macro=true";
import { default as indexnKjYELXo74Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue?macro=true";
import { default as indexR8fTh2HZU6Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/company/index.vue?macro=true";
import { default as indexGx96BY63XdMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/mirage-experience/index.vue?macro=true";
import { default as indexHsf8pj5MOiMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue?macro=true";
import { default as indexIb7QZ1hvBFMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/resource/index.vue?macro=true";
import { default as indexRFvp2z1pvvMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/sustainability/index.vue?macro=true";
import { default as _91id_93cfgppFCvihMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/moodboards/[id].vue?macro=true";
import { default as _91id_93qZhObPGwVAMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/news/[id].vue?macro=true";
import { default as indexgGdlZPgGauMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/press/index.vue?macro=true";
import { default as indexxXJrPvUqtcMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/[id]/index.vue?macro=true";
import { default as indexrOUmwZB9G1Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/atelier/index.vue?macro=true";
import { default as _91id_939kkojGc3H0Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/collections/[id].vue?macro=true";
import { default as indexrdilIj9IF7Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/collections/index.vue?macro=true";
import { default as indexRMWHbnQPuCMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/find-product/index.vue?macro=true";
import { default as _91id_93Bpyn76WjXOMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/style-and-mood/[id].vue?macro=true";
import { default as _91id_934311ObqcmTMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/projects/[id].vue?macro=true";
import { default as indexXJxgg6wf3wMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/certifications/index.vue?macro=true";
import { default as indexosO6ORBRgFMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/collections/index.vue?macro=true";
import { default as indexltigD4qODLMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/handbooks/index.vue?macro=true";
import { default as indexrb6CpEwQbwMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/monographs/index.vue?macro=true";
import { default as resourcesxq8MQP9CHbMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources.vue?macro=true";
import { default as indexLWHfpMPxIoMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/search/index.vue?macro=true";
import { default as indexCI3LrJN9leMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signin/index.vue?macro=true";
import { default as indexbvJ52lVFFmMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signin/password-recovery/index.vue?macro=true";
import { default as indexHwU3WiPORvMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/activated/index.vue?macro=true";
import { default as indexMUUchv6kasMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/activation/index.vue?macro=true";
import { default as indexjy4KVOUcdeMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/index.vue?macro=true";
import { default as indexGWio17cZj5Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/private/index.vue?macro=true";
import { default as index0xpHbunReCMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/professional/index.vue?macro=true";
import { default as index1ZyRXDjslDMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/bathroom/index.vue?macro=true";
import { default as indexWkRluvXVozMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/engineering/index.vue?macro=true";
import { default as indexpJ9M8EFlr2Meta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue?macro=true";
import { default as indexs7tJ1vcAuXMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue?macro=true";
import { default as indexjLsC6h4rtHMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue?macro=true";
import { default as index6zkRQ7j3ZbMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue?macro=true";
import { default as indexyPL5FuxwFSMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/worktops/index.vue?macro=true";
import { default as indexOjb4TirrnVMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/store-locator/index.vue?macro=true";
import { default as indexATAks3GTTcMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/design-stories/index.vue?macro=true";
import { default as indexlL97thJwBhMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/news/index.vue?macro=true";
import { default as indexOm3TaGlmgmMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/projects/index.vue?macro=true";
import { default as indexAid4ijlJXaMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/trends/index.vue?macro=true";
import { default as storiesq9kOHwL8nvMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories.vue?macro=true";
import { default as indexSFOJ4gD36JMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as indexDJwtNeZkMhMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue?macro=true";
import { default as index9EphatqlDxMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue?macro=true";
import { default as indexhvSdqybz7EMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue?macro=true";
import { default as indexF2FyhBbzvvMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue?macro=true";
import { default as index4ClbgLcd7bMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-hr-images-request/index.vue?macro=true";
import { default as indexNJZHudqebpMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue?macro=true";
import { default as supportCYKacXeODCMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support.vue?macro=true";
import { default as _91id_93KJlxp7QBlCMeta } from "/var/www/html/mirage/frontend-2/pages/[country]/[locale]/trends/[id].vue?macro=true";
import { default as indexwK1v9g9XrrMeta } from "/var/www/html/mirage/frontend-2/pages/index.vue?macro=true";
export default [
  {
    name: "country-locale-404",
    path: "/:country()/:locale()/404",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/404.vue")
  },
  {
    name: "country-locale-account",
    path: "/:country()/:locale()/account",
    meta: indexMoVxlzVBcpMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/index.vue")
  },
  {
    name: "country-locale-account-password-type",
    path: "/:country()/:locale()/account/password-:type()",
    meta: indexFv8nlj2s0KMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/password-[type]/index.vue")
  },
  {
    name: "country-locale-account-personal-area",
    path: "/:country()/:locale()/account/personal-area",
    meta: personal_45areaaaa7INZmYSMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area.vue"),
    children: [
  {
    name: "country-locale-account-personal-area-favourites",
    path: "favourites",
    meta: indexYRkbs01K0VMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/favourites/index.vue")
  },
  {
    name: "country-locale-account-personal-area-hires-imgs",
    path: "hires-imgs",
    meta: indexryNDCvIN71Meta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/hires-imgs/index.vue")
  },
  {
    name: "country-locale-account-personal-area-moodboards",
    path: "moodboards",
    meta: indexuXLcImnDL3Meta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/personal-area/moodboards/index.vue")
  }
]
  },
  {
    name: "country-locale-account-profile",
    path: "/:country()/:locale()/account/profile",
    meta: profiletbdS0DqYXiMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile.vue"),
    children: [
  {
    name: "country-locale-account-profile-change-password",
    path: "change-password",
    meta: index83xmtm5UxEMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile/change-password/index.vue")
  },
  {
    name: "country-locale-account-profile-info",
    path: "info",
    meta: index4buNDtAbiwMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/account/profile/info/index.vue")
  }
]
  },
  {
    name: "country-locale-architects-and-designers",
    path: "/:country()/:locale()/architects-and-designers",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/architects-and-designers/index.vue")
  },
  {
    name: "country-locale-contacts",
    path: "/:country()/:locale()/contacts",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/contacts/index.vue")
  },
  {
    name: "country-locale-demo-block",
    path: "/:country()/:locale()/demo/block",
    meta: blocklJSqu6vk8FMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block.vue"),
    children: [
  {
    name: "country-locale-demo-block-carousel",
    path: "carousel",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/carousel/index.vue")
  },
  {
    name: "country-locale-demo-block-divider",
    path: "divider",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/divider/index.vue")
  },
  {
    name: "country-locale-demo-block-form",
    path: "form",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/form/index.vue")
  },
  {
    name: "country-locale-demo-block-grid",
    path: "grid",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/grid/index.vue")
  },
  {
    name: "country-locale-demo-block-launcher",
    path: "launcher",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/launcher/index.vue")
  },
  {
    name: "country-locale-demo-block-media",
    path: "media",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/media/index.vue")
  },
  {
    name: "country-locale-demo-block-text",
    path: "text",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/text/index.vue")
  },
  {
    name: "country-locale-demo-block-toolbar",
    path: "toolbar",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/block/toolbar/index.vue")
  }
]
  },
  {
    name: "country-locale-demo-carousel-pin",
    path: "/:country()/:locale()/demo/carousel-pin",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/carousel-pin.vue")
  },
  {
    name: "country-locale-demo-composer",
    path: "/:country()/:locale()/demo/composer",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/composer.vue")
  },
  {
    name: "country-locale-demo-ease",
    path: "/:country()/:locale()/demo/ease",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/ease.vue")
  },
  {
    name: "country-locale-demo-geolist",
    path: "/:country()/:locale()/demo/geolist",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/geolist.vue")
  },
  {
    name: "country-locale-demo",
    path: "/:country()/:locale()/demo",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/index.vue")
  },
  {
    name: "country-locale-demo-media-options",
    path: "/:country()/:locale()/demo/media-options",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/media-options.vue")
  },
  {
    name: "country-locale-demo-surfer",
    path: "/:country()/:locale()/demo/surfer",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/demo/surfer.vue")
  },
  {
    name: "country-locale-design-stories-id",
    path: "/:country()/:locale()/design-stories/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/design-stories/[id].vue")
  },
  {
    name: "country-locale-faq",
    path: "/:country()/:locale()/faq",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/faq/index.vue")
  },
  {
    name: "country-locale",
    path: "/:country()/:locale()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/index.vue")
  },
  {
    name: "country-locale-info-and-legal-cookie-policy",
    path: "/:country()/:locale()/info-and-legal/cookie-policy",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/cookie-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-copyright-disclaimer",
    path: "/:country()/:locale()/info-and-legal/copyright-disclaimer",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/copyright-disclaimer/index.vue")
  },
  {
    name: "country-locale-info-and-legal-privacy-policy",
    path: "/:country()/:locale()/info-and-legal/privacy-policy",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/privacy-policy/index.vue")
  },
  {
    name: "country-locale-info-and-legal-terms-of-sale",
    path: "/:country()/:locale()/info-and-legal/terms-of-sale",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/terms-of-sale/index.vue")
  },
  {
    name: "country-locale-info-and-legal-whistleblowing",
    path: "/:country()/:locale()/info-and-legal/whistleblowing",
    meta: indexnKjYELXo74Meta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/info-and-legal/whistleblowing/index.vue")
  },
  {
    name: "country-locale-mirage-world-company",
    path: "/:country()/:locale()/mirage-world/company",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/company/index.vue")
  },
  {
    name: "country-locale-mirage-world-mirage-experience",
    path: "/:country()/:locale()/mirage-world/mirage-experience",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/mirage-experience/index.vue")
  },
  {
    name: "country-locale-mirage-world-porcelain-tiles",
    path: "/:country()/:locale()/mirage-world/porcelain-tiles",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/porcelain-tiles/index.vue")
  },
  {
    name: "country-locale-mirage-world-resource",
    path: "/:country()/:locale()/mirage-world/resource",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/resource/index.vue")
  },
  {
    name: "country-locale-mirage-world-sustainability",
    path: "/:country()/:locale()/mirage-world/sustainability",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/mirage-world/sustainability/index.vue")
  },
  {
    name: "country-locale-moodboards-id",
    path: "/:country()/:locale()/moodboards/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/moodboards/[id].vue")
  },
  {
    name: "country-locale-news-id",
    path: "/:country()/:locale()/news/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/news/[id].vue")
  },
  {
    name: "country-locale-press",
    path: "/:country()/:locale()/press",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/press/index.vue")
  },
  {
    name: "country-locale-products-id",
    path: "/:country()/:locale()/products/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/[id]/index.vue")
  },
  {
    name: "country-locale-products-atelier",
    path: "/:country()/:locale()/products/atelier",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/atelier/index.vue")
  },
  {
    name: "country-locale-products-collections-id",
    path: "/:country()/:locale()/products/collections/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/collections/[id].vue")
  },
  {
    name: "country-locale-products-collections",
    path: "/:country()/:locale()/products/collections",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/collections/index.vue")
  },
  {
    name: "country-locale-products-find-product",
    path: "/:country()/:locale()/products/find-product",
    meta: indexRMWHbnQPuCMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/find-product/index.vue")
  },
  {
    name: "country-locale-products-style-and-mood-id",
    path: "/:country()/:locale()/products/style-and-mood/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/products/style-and-mood/[id].vue")
  },
  {
    name: "country-locale-projects-id",
    path: "/:country()/:locale()/projects/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/projects/[id].vue")
  },
  {
    name: "country-locale-resources",
    path: "/:country()/:locale()/resources",
    meta: resourcesxq8MQP9CHbMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources.vue"),
    children: [
  {
    name: "country-locale-resources-certifications",
    path: "certifications",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/certifications/index.vue")
  },
  {
    name: "country-locale-resources-collections",
    path: "collections",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/collections/index.vue")
  },
  {
    name: "country-locale-resources-handbooks",
    path: "handbooks",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/handbooks/index.vue")
  },
  {
    name: "country-locale-resources-monographs",
    path: "monographs",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/resources/monographs/index.vue")
  }
]
  },
  {
    name: "country-locale-search",
    path: "/:country()/:locale()/search",
    meta: indexLWHfpMPxIoMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/search/index.vue")
  },
  {
    name: "country-locale-signin",
    path: "/:country()/:locale()/signin",
    meta: indexCI3LrJN9leMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signin/index.vue")
  },
  {
    name: "country-locale-signin-password-recovery",
    path: "/:country()/:locale()/signin/password-recovery",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signin/password-recovery/index.vue")
  },
  {
    name: "country-locale-signup-activated",
    path: "/:country()/:locale()/signup/activated",
    meta: indexHwU3WiPORvMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/activated/index.vue")
  },
  {
    name: "country-locale-signup-activation",
    path: "/:country()/:locale()/signup/activation",
    meta: indexMUUchv6kasMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/activation/index.vue")
  },
  {
    name: "country-locale-signup",
    path: "/:country()/:locale()/signup",
    meta: indexjy4KVOUcdeMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/index.vue")
  },
  {
    name: "country-locale-signup-private",
    path: "/:country()/:locale()/signup/private",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/private/index.vue")
  },
  {
    name: "country-locale-signup-professional",
    path: "/:country()/:locale()/signup/professional",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/signup/professional/index.vue")
  },
  {
    name: "country-locale-solutions-bathroom",
    path: "/:country()/:locale()/solutions/bathroom",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/bathroom/index.vue")
  },
  {
    name: "country-locale-solutions-engineering",
    path: "/:country()/:locale()/solutions/engineering",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/engineering/index.vue")
  },
  {
    name: "country-locale-solutions-floor-and-wall-tiles",
    path: "/:country()/:locale()/solutions/floor-and-wall-tiles",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/floor-and-wall-tiles/index.vue")
  },
  {
    name: "country-locale-solutions-outdoor-and-gardening",
    path: "/:country()/:locale()/solutions/outdoor-and-gardening",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/outdoor-and-gardening/index.vue")
  },
  {
    name: "country-locale-solutions-pools-and-wellness",
    path: "/:country()/:locale()/solutions/pools-and-wellness",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/pools-and-wellness/index.vue")
  },
  {
    name: "country-locale-solutions-tables-and-furniture",
    path: "/:country()/:locale()/solutions/tables-and-furniture",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/tables-and-furniture/index.vue")
  },
  {
    name: "country-locale-solutions-worktops",
    path: "/:country()/:locale()/solutions/worktops",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/solutions/worktops/index.vue")
  },
  {
    name: "country-locale-store-locator",
    path: "/:country()/:locale()/store-locator",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/store-locator/index.vue")
  },
  {
    name: "country-locale-stories",
    path: "/:country()/:locale()/stories",
    meta: storiesq9kOHwL8nvMeta || {},
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories.vue"),
    children: [
  {
    name: "country-locale-stories-design-stories",
    path: "design-stories",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/design-stories/index.vue")
  },
  {
    name: "country-locale-stories-news",
    path: "news",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/news/index.vue")
  },
  {
    name: "country-locale-stories-projects",
    path: "projects",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/projects/index.vue")
  },
  {
    name: "country-locale-stories-trends",
    path: "trends",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/stories/trends/index.vue")
  }
]
  },
  {
    name: "country-locale-support",
    path: "/:country()/:locale()/support",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support.vue"),
    children: [
  {
    name: "country-locale-support-private-after-sales-and-installation-technical-assistance",
    path: "private-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-after-sales-and-installation-technical-assistance/index.vue")
  },
  {
    name: "country-locale-support-private-commercial-consultancy",
    path: "private-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-private-product-info-and-solutions",
    path: "private-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/private-product-info-and-solutions/index.vue")
  },
  {
    name: "country-locale-support-professional-after-sales-and-installation-technical-assistance",
    path: "professional-after-sales-and-installation-technical-assistance",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-after-sales-and-installation-technical-assistance/index.vue")
  },
  {
    name: "country-locale-support-professional-commercial-consultancy",
    path: "professional-commercial-consultancy",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-commercial-consultancy/index.vue")
  },
  {
    name: "country-locale-support-professional-hr-images-request",
    path: "professional-hr-images-request",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-hr-images-request/index.vue")
  },
  {
    name: "country-locale-support-professional-product-info-and-solutions",
    path: "professional-product-info-and-solutions",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/support/professional-product-info-and-solutions/index.vue")
  }
]
  },
  {
    name: "country-locale-trends-id",
    path: "/:country()/:locale()/trends/:id()",
    component: () => import("/var/www/html/mirage/frontend-2/pages/[country]/[locale]/trends/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/html/mirage/frontend-2/pages/index.vue")
  }
]