export default [
  "/var/www/html/mirage/frontend-2/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/components/global/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/components/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/layouts/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/pages/**/*.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/plugins/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend-2/composables/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend-2/utils/**/*.{js,ts,mjs}",
  "/var/www/html/mirage/frontend-2/{A,a}pp.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/{E,e}rror.{vue,js,jsx,mjs,ts,tsx}",
  "/var/www/html/mirage/frontend-2/app.config.{js,ts,mjs}",
  "formkit.config.js",
  "/var/www/html/mirage/frontend-2/formkit.config.js"
]