import { get } from "@vueuse/core";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", () => {
  const type = useCookie("mrg_usertype", {
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
  });

  const token = useCookie("mrg_token", {
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
  });

  const id = useCookie("mrg_id", {
    maxAge: 60 * 60 * 24 * 365,
    path: "/",
  });

  const _user = ref({});
  const _moodboards = ref([]);
  const _currentMoodboard = ref();
  const _cachedMoodboard = ref();

  const user = computed(() => _user.value);
  const route = useRoute();

  const userModel = {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    type: undefined, // private | professional
    title: undefined,
    country_code: undefined,
    privacy: undefined,
    marketing: undefined,
    moodboards: undefined,
    favourites: undefined,
    currentMoodboard: undefined,
  };

  const logged = computed(() => {
    return !!user.value.email;
  });

  async function login(params) {
    return await $fetch(`/api/login`, {
      method: "POST",
      body: JSON.stringify({
        lng: "it",
        ...params,
      }),
    }).then((data) => {
      console.log("login", data);
      if (data?.status == "success") {
        setUser({
          id: data.id,
          token: data.token,
        });
      }
      return data;
    });
  }

  async function logout(params) {
    return await $fetch(`/api/logout`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        id: id.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        setUser(userModel);
      }
      return data;
    });
  }

  async function signup(params) {
    return await $fetch(`/api/signup`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        ...params,
      }),
    });
  }

  async function update(params) {
    return await $fetch(`/api/update`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        setUser(data.user);
      }
      return data;
    });
  }

  async function passwordRecoveryRequest(payload) {
    // richiesta di cambio pw da non loggato (pw dimenticata)
    return await $fetch(`/api/password-recovery-request`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        ...payload,
      }),
    });
  }

  async function passwordUpdateRequest(payload) {
    // richiesta di cambio pw da loggato
    return await $fetch(`/api/password-update-request`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        ...payload,
      }),
    });
  }

  async function passwordChange(payload) {
    return await $fetch(`/api/password-change`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        ...payload,
      }),
    });
  }

  async function validate() {
    return $fetch(`/api/validate`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        id: id.value,
        token: token.value,
      }),
    }).then((data) => {
      if (data?.user) {
        setUser(data.user);
      } else {
        setUser(userModel);
      }
      return data;
    });
    // return setUser(data.value?.user || userModel);
  }

  async function createMoodboard(params) {
    return await $fetch(`/api/create-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        setUser(data.user);
      }
      return data;
    });
  }

  async function duplicateMoodboard(params) {
    return await $fetch(`/api/duplicate-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      console.log("duplicateMoodboard", params, data);
      return data;
    });
  }

  async function deleteMoodboard(params, cache = true) {
    if (cache) {
      _cachedMoodboard.value = {
        slug: params.moodboard,
        ..._currentMoodboard.value,
      };
    }

    return await $fetch(`/api/delete-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      return data;
    });
  }

  async function updateMoodboard(params) {
    return await $fetch(`/api/update-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        _currentMoodboard.value = data.moodboard;
        setUser(data.user);
      }
      return data;
    });
  }

  async function addToMoodboard(params) {
    return await $fetch(`/api/add-to-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        setUser(data.user);
      }
      return data;
    });
  }

  async function removeFromMoodboard(params) {
    const index = _currentMoodboard.value.products.findIndex(
      (el) => el.slug == params.product_slug
    );

    if (index > -1) {
      _currentMoodboard.value.products.splice(index, 1);
    }

    return await $fetch(`/api/remove-from-moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        ...params,
      }),
    }).then((data) => {
      if (data?.status == "success") {
        setUser(data.user);
      }
      return data;
    });
  }

  function isMoodboardOwner(slug) {
    return (
      logged.value &&
      (user.value.moodboards.filter((el) => slug == el.slug).length > 0 ||
        _cachedMoodboard.value?.slug == slug)
    );
  }

  function getMoodboard(slug) {
    return user.value?.moodboards?.filter((el) => slug == el.slug)[0];
  }

  function setUser(data) {
    _user.value = { ...data };
    token.value = data.token ? data.token : null;
    id.value = data.id ? data.id : null;
    type.value = data.type ? data.type : null;
    return _user.value;
  }

  function setMoodboards(data) {
    _moodboards.value = data;
    return _moodboards.value;
  }

  async function fetchMoodboard(slug) {
    return await $fetch(`/api/get_moodboard`, {
      method: "POST",
      body: JSON.stringify({
        lng: route.params.locale,
        user_id: id.value,
        token: token.value,
        slug,
      }),
    }).then((data) => {
      _currentMoodboard.value = data;
      return data;
    });
  }

  function clearMoodboard() {
    _currentMoodboard.value = null;
    _cachedMoodboard.value = null;
  }

  return {
    logged,
    fullName: computed(() => {
      return `${user.value.first_name} ${user.value.last_name}`;
    }),
    favourites: computed(() => user.value.favourites),
    currentMoodboard: computed(
      () => _currentMoodboard.value || _cachedMoodboard.value
    ),
    hasToken: computed(() => !!token.value),
    user,
    login,
    logout,
    signup,
    update,
    passwordUpdateRequest,
    passwordRecoveryRequest,
    passwordChange,
    createMoodboard,
    duplicateMoodboard,
    deleteMoodboard,
    updateMoodboard,
    getMoodboard,
    addToMoodboard,
    removeFromMoodboard,
    validate,
    isMoodboardOwner,
    setMoodboards,
    fetchMoodboard,
    clearMoodboard,
  };
});
