<template>
  <div component-name="input-professional-title" class="contents">
    <input-company v-if="useCompany" :is-required="isRequired" />

    <form-kit
      type="select"
      name="title"
      :label="`${$t.labels.profession_title} *`"
      :options="jobtitlesStore.options"
      :placeholder="$t.labels.select_option"
      validation="required"
    />
  </div>
</template>

<script setup>
import { useJobtitlesStore } from "@/stores/jobtitles";
const jobtitlesStore = useJobtitlesStore();

const props = defineProps({
  useCompany: {
    type: Boolean,
    required: false,
    default: false,
  },
  isRequired: {
    type: Boolean,
    required: false,
    default: false,
  },
});
</script>
