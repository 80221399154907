import { defineStore } from "pinia";

export const useJobtitlesStore = defineStore("jobtitles", () => {
  const nuxtApp = useNuxtApp();

  const _optionsProfessionalTitle = ref([
    { label: nuxtApp.$t.labels.jobtitle_architect, value: "Architect" },
    { label: nuxtApp.$t.labels.jobtitle_engineer, value: "Engineer" },
    {
      label: nuxtApp.$t.labels.jobtitle_interiordesigner_planner,
      value: "Interior Designer / Planner",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_landscape_hardscape_designer,
      value: "Landscape / Hardscape Designer",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_dealer_distributor_reseller,
      value: "Dealer / Distributor / Reseller",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_salesconsultant_showroommanager,
      value: "Sales Consultant / Showroom Manager",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_installer_layingcompany,
      value: "Installer / Laying company",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_constructioncompany_generalcontractor,
      value: "Construction Company / General Contractor",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_realestate_retailbuyer,
      value: "Real Estate / Retail Buyer",
    },
    {
      label: nuxtApp.$t.labels.jobtitle_journalist_blogger,
      value: "Journalist / Blogger",
    },
    { label: nuxtApp.$t.labels.jobtitle_other, value: "Other" },
  ]);

  function _getLabel(value) {
    let label = "";

    for (
      let i = 0, len = _optionsProfessionalTitle.value.length;
      i < len;
      i++
    ) {
      if (_optionsProfessionalTitle.value[i].value === value) {
        label = _optionsProfessionalTitle.value[i].label;
        break;
      }
    }

    return label;
  }

  return {
    options: computed(() => _optionsProfessionalTitle.value),
    getLabel: (value) => _getLabel(value),
  };
});
