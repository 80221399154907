<template>
  <btn
    component-name="btn-nav"
    :title="$attrs.title"
    :action="$attrs.action ? $attrs.action : undefined"
    :disabled="$attrs.disabled ? $attrs.disabled : undefined"
    :pressed="$attrs.pressed ? $attrs.pressed : undefined"
    :size="$attrs.size ? $attrs.size : undefined"
    class="leading-none underline underline-offset-2 decoration-1 decoration-transparent inline-flex items-center gap-xs min-w-min text-skin-base whitespace-nowrap break-keep"
    :class="{
      'text-button': !$attrs.size || $attrs.size === 'regular',
      'text-button-small': $attrs.size === 'small',
      'text-button-big': $attrs.size === 'big',
      'text-heading-4': $attrs.size === 'huge',
      '!decoration-current': $attrs.pressed,
    }"
    @mouseenter="$device.isDesktop ? addText() : () => {}"
  >
    <hover-icon
      v-if="iconLeft"
      ref="tpl_hoverIconLeft"
      :icon="iconLeft"
      :size="$attrs.size === 'big' ? 'big' : 'regular'"
      inert
    />

    <div
      inert
      class="relative top-0 left-0 block whitespace-nowrap break-keep select-none leading-none"
    >
      <span
        class="subpixel-antialiased opacity-0 select-none"
        v-html="$attrs.title"
      ></span>

      <span
        ref="tpl_txtWrapper"
        class="overflow-hidden absolute inset-0 grid grid-cols-1 w-full h-full [&>*]:row-span-full [&>*]:col-start-1 [&>*]:col-end-2"
      >
        <span class="subpixel-antialiased" v-html="$attrs.title"></span>
      </span>
    </div>

    <hover-icon
      v-if="iconRight"
      ref="tpl_hoverIconRight"
      :icon="iconRight"
      :size="$attrs.size === 'big' ? 'big' : 'regular'"
      inert
    />
  </btn>
</template>

<script setup>
const props = defineProps({
  iconLeft: {
    type: String,
    required: false,
  },
  iconRight: {
    type: String,
    required: false,
  },
});

const attrs = useAttrs();
const isAnimating = ref(false);
const tpl_hoverIconLeft = templateRef("tpl_hoverIconLeft");
const tpl_txtWrapper = templateRef("tpl_txtWrapper");
const tpl_hoverIconRight = templateRef("tpl_hoverIconRight");

function addText() {
  if (isAnimating.value) return;

  isAnimating.value = true;

  const node = document.createElement("span");
  const txt = document.createTextNode(attrs.title);
  node.appendChild(txt);

  tpl_txtWrapper.value.appendChild(node);

  const elToRemove =
    tpl_txtWrapper.value.children[tpl_txtWrapper.value.children.length - 2];

  const tl = gsap.timeline({
    onStart: () => {
      if (props.iconLeft) tpl_hoverIconLeft.value.animate();
      if (props.iconRight) tpl_hoverIconRight.value.animate();
    },
    onComplete: () => {
      elToRemove.remove();
      isAnimating.value = false;
    },
  });

  tl.to(elToRemove, { yPercent: -200, duration: 0.65, ease: "expo.inOut" });
  tl.from(
    tpl_txtWrapper.value.lastChild,
    { yPercent: 200, duration: 0.65, ease: "expo.inOut" },
    "<"
  );
}
</script>
